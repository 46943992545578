.transaction-history-wrapper{
    border: 1px solid #E4E7EC;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    border-radius: 8px;
    background-color: #fff;
}
.transaction-history-wrapper .transaction-history-header{
    padding: 20px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom:1px solid #E4E7EC;
}
.transaction-history-wrapper .transaction-history-header .header-title{
    display: flex;
    align-items: center;
}
.transaction-history-wrapper .transaction-history-header .header-title .image img{
    margin-right: 16px;
}
.transaction-history-wrapper .transaction-history-header .header-title .text{
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
}
.transaction-history-wrapper .transaction-history-header .header-action{
    display: flex;
    align-items: center;
    justify-content: center;
}
.transaction-history-wrapper .transaction-history-header .header-action .search-box{
    position: relative;
}
.transaction-history-wrapper .transaction-history-header .header-action .search-box div label{
    display: none;
}
.transaction-history-wrapper .transaction-history-header .header-action .search-box input{
    width: 320px;
    padding: 10px 14px 10px 42px!important;
}
.transaction-history-wrapper .transaction-history-header .header-action .search-box:before{
    position: absolute;
    content: "";
    background: url('/public/assets/images/search.svg') no-repeat center;
    left: 16px;
    top: 14px;
    width: 17px;
    height: 17px;
    z-index: 4;
}
.transaction-history-wrapper .transaction-history-header .header-action .filter-btn-wrapper{
    margin-right: 8px;
    margin-left: 24px;
}
.transaction-history-wrapper .transaction-history-header .header-action .filter-btn-wrapper > div,
.transaction-history-wrapper .transaction-history-header .header-action .download-btn-wrapper > div{
    margin-top: 0!important;
}
.transaction-history-wrapper .transaction-history-header .header-action .filter-btn-wrapper button,
.transaction-history-wrapper .transaction-history-header .header-action .download-btn-wrapper button{
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    padding: 10px 16px 10px 44px!important;
    color: #344054;
    font-size: 14px;
    position: relative;
}
.transaction-history-wrapper .transaction-history-header .header-action .download-btn-wrapper button{
    padding: 10px 35px 10px 44px!important;
}
.transaction-history-wrapper .transaction-history-header .header-action .download-btn-wrapper{
    position: relative;
}
.transaction-history-wrapper .transaction-history-header .header-action .download-btn-wrapper::after{
    position: absolute;
    content: "";
    right: 18px;
    top: 20px;
    width: 7px;
    height: 4px;
    background: url('/public/assets/images/download-arrow.svg') no-repeat center;
}
.transaction-history-wrapper .transaction-history-header .header-action .filter-btn-wrapper button::before{
    position: absolute;
    content: "";
    background: url('/public/assets/images/filter.svg') no-repeat center;
    left: 18px;
    top: 15px;
    width: 15px;
    height: 12px;
}
.transaction-history-wrapper .transaction-history-header .header-action .download-btn-wrapper button::before{
    position: absolute;
    content: "";
    background: url('/public/assets/images/download.svg') no-repeat center;
    left: 17px;
    top: 12px;
    width: 17px;
    height: 17px;
}
.transaction-history-wrapper .transaction-history-header .header-action .filter-btn-wrapper button:hover,
.transaction-history-wrapper .transaction-history-header .header-action .download-btn-wrapper button:hover{
    color: #344054;
    background: #F9FAFB; 
}
.transaction-history-wrapper .transaction-history-header .header-action .download-btn-wrapper .download-dropdown-list{
    position: absolute;
    z-index: 2;
    background-color: #fff;
    width: 148px;
    box-shadow: 0px 12px 16px -4px rgb(16 24 40 / 10%), 0px 4px 6px -2px rgb(16 24 40 / 5%);
    border-radius: 8px;
}
.transaction-history-wrapper .transaction-history-header .header-action .download-btn-wrapper .download-dropdown-list ul{
    margin-bottom: 0;
    padding: 0;
}
.transaction-history-wrapper .transaction-history-header .header-action .download-btn-wrapper .download-dropdown-list ul li{
    list-style: none;
    padding: 12px 17px 12px 42px;
    position: relative;
}
.transaction-history-wrapper .transaction-history-header .header-action .download-btn-wrapper .download-dropdown-list ul li.csv-link::before{
    position: absolute;
    content: "";
    background: url('/public/assets/images/csv.svg') no-repeat center;
    top: 16px;
    left: 17px;
    width: 17px;
    height: 19px;
}
.transaction-history-wrapper .transaction-history-header .header-action .download-btn-wrapper .download-dropdown-list ul li.pdf-link::before{
    position: absolute;
    content: "";
    background: url('/public/assets/images/pdf.svg') no-repeat center;
    top: 16px;
    left: 17px;
    width: 17px;
    height: 19px;
}
.transaction-history-wrapper .transaction-history-header .header-action .download-btn-wrapper .download-dropdown-list ul li:hover{
    background: #F9FAFB;
    border-radius: 8px; 
}
.transaction-history-wrapper .transaction-history-header .header-action .download-btn-wrapper .download-dropdown-list ul li a{
    color: #0F0742;
    font-weight: 300;
    text-decoration: none;
    display: block;
}
.transaction-history-wrapper .rdt_Table .rdt_TableHead .rdt_TableHeadRow{
    background: #F9FAFB; 
}
.transaction-history-wrapper .rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol .rdt_TableCol_Sortable{
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #667085;
}
.transaction-history-wrapper .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell{
    padding: 16px;
}
.transaction-history-wrapper .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell div{
    font-size: 14px;
    font-weight: 300;
}
.transaction-history-wrapper .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell:nth-of-type(1) div .name,
.transaction-history-wrapper .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell:nth-of-type(5) div{
    font-weight: 500;
    color: #101828;
}
.transaction-history-wrapper .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell[data-column-id="7"]{
    background-color: #FCFCFD;
    color: #039855;
}
.transaction-history-wrapper .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell[data-column-id="7"] div{
    font-weight: 500;
}
.transaction-history-wrapper .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell[data-column-id="6"]{
    color: #FF8F50;
}
.transaction-history-wrapper .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell[data-column-id="6"] div{
    font-weight: 500;
}
.transaction-history-wrapper .rdt_Table button.fullfill-btn {
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
    border-radius: 100px;
    padding: 8px 14px;
    font-weight: 500;
    color: #344054;
}
.transaction-history-wrapper .rdt_Table .refunded-text{
    text-align: center;
    display: block;
    font-weight: 500;
    color: #F04438 !important;
}
.transaction-history-wrapper .rdt_Pagination{
    background-color: transparent;
    position: relative;
}
.transaction-history-wrapper .rdt_Pagination #pagination-first-page,
.transaction-history-wrapper .rdt_Pagination #pagination-last-page{
    display:none
}
.transaction-history-wrapper .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell[data-column-id="4"],
.transaction-history-wrapper .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCol[data-column-id="4"]{
    min-width: auto;
}
.transaction-history-wrapper .rdt_Table .rdt_TableCol .eUeqdG{
    overflow: initial;
    white-space: initial;
}

@media screen and (min-width:1025px) and (max-width:1199px){
    .transaction-history-wrapper .transaction-history-header{
        flex-direction: column;
        align-items: flex-start;
    }
    .transaction-history-wrapper .transaction-history-header .header-action .search-box{
        margin: 15px 0 0;
    }
    .transaction-history-wrapper .transaction-history-header .header-action .filter-btn-wrapper,
    .transaction-history-wrapper .transaction-history-header .header-action .download-btn-wrapper{
        margin-top: 15px;
    }
}

@media screen and (max-width:1024px){
    .transaction-history-wrapper .transaction-history-header{
        flex-direction: column;
        align-items: flex-start;
    }
    .transaction-history-wrapper .transaction-history-header .header-action{
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .transaction-history-wrapper .transaction-history-header .header-action .search-box{
        width: auto;
        margin: 15px 0 0;
    }
    .transaction-history-wrapper .transaction-history-header .header-action .search-box input{
        width: auto;
    }
    .transaction-history-wrapper .transaction-history-header .header-action .filter-btn-wrapper,
    .transaction-history-wrapper .transaction-history-header .header-action .download-btn-wrapper{
        margin-top: 15px;
    }
}

@media screen and (max-width:991px){
    .transaction-history-wrapper .transaction-history-header .header-action{
        width: 100%;
    }
    .transaction-history-wrapper .transaction-history-header .header-action .search-box{
        width: 60%;
        margin: 15px 15px 15px 0;
    }
    .transaction-history-wrapper .transaction-history-header .header-action .search-box input{
        width: auto;
    }
    .transaction-history-wrapper .transaction-history-header .header-action .filter-btn-wrapper{
        margin-left: 0;
    }
    .business-profile-card .opening-hours .day-name{
        font-size: 13px;
    }
}
@media screen and (max-width:767px){
    .transaction-history-wrapper .transaction-history-header .header-action .search-box{
        width: 100%;
        margin: 15px 0;
    }
    .transaction-history-wrapper .transaction-history-header .header-action .filter-btn-wrapper,
    .transaction-history-wrapper .transaction-history-header .header-action .download-btn-wrapper{
        margin-top: 0;
    }
}
.frozen-transaction * {
    color: #D0D5DD !important;
}