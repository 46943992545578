.app-button {
  border-radius: 100px !important;
  padding: 10px 18px !important;
  font-weight: 500 !important;
}
.app-button.btn-primary {
  background-color: #52ff9a;
  border-color: #52ff9a;
  color: #000000;
}
.app-button.error-bg {
  border-color: #d92d20 !important;
  color: #ffffff;
}
#button-addon2 {
  line-height: 1;
}

.button-prefix-icon:active,
.button-prefix-icon:focus,
.button-prefix-icon:hover {
  box-shadow: unset !important;
  color: #000000 !important;
}
