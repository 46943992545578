.searched-place .main-card-color{
  background-color: #f9fafb !important;
  padding: 16px 16px 18px;
  border: 0!important;
  border-radius: 14px!important;
}
.searched-place .company-info-card{
  border-radius: 8px!important;
  border:1px solid #E4E7EC!important;
  padding: 16px!important;
}
.searched-place .image-wrapper{
  height: 90px;
  width: 90px;
  border: 1px solid #e4e7ec;
  box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
  border-radius: 100%;
  line-height: 90px;
  text-align: center;
  overflow: hidden;
}
.company-info-card .content-area{
  padding-left: 10px;
}
.company-info-card .content-area h4{
  margin-bottom: 0;
  font-size: 24px;
  color:#0F0742;
  line-height: 32px;
}
.company-info-card .content-area span{
  font-size: 12px;
  color: #667085;
  font-weight: 500;
  line-height: 14px;
}
.business-search-wrapper > div{
  position: relative;
}
.business-search-wrapper > div label{
  display: none;
}
.business-search-wrapper > div .input-group::before{
  content: "";
  position: absolute;
  background: url('/public/assets/images/search.svg') no-repeat center;
  left: 16px;
  top: 14.5px;
  width: 17px;
  height: 17px;
  z-index: 4;
}
.business-search-wrapper > div .input-group input{
  padding: 10px 14px 10px 42px!important;
}
