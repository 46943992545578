.main-box.signin-main .onboarding-content-card{
  margin-top: 0;
}
.onboarding-content-card .logo {
  height: 70px;
  width: 90px;
}

.onboarding-content-card {
  border-radius: 18px!important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05)!important;
  /* max-height: 85vh;
  overflow-y: auto; 
  margin-top: 80px;*/
}
