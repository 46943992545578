.merchant-account-status {
  background: #0f0742;
  border-radius: 16px;
  padding: 16px;
  margin-top: 16px;
}
.merchant-account-status .top-button-custom{
  position: relative;
}
.merchant-account-status .top-button-custom .before-dot{
  position: absolute;
}
