.admin-sidebar-menu{
    position: relative;
}
.admin-sidebar-menu .side-menu .menu-items .list-group .list-group-item:last-child{
    position: absolute;
    bottom: 100px;
}
.admin-sidebar-menu .side-menu .menu-items .list-group .list-group-item .child-menu .list-group-item:last-child{
    position: initial;
}
.admin-sidebar-menu .side-menu .admin-info img{
    margin-right: 12px;
    border-radius: 100%;
}
.admin-sidebar-menu .side-menu .admin-info span{
    font-weight: 500;
}
.admin-sidebar-menu .side-menu .menu-items .list-group .active.active-child .child-menu{
    margin-bottom: -20px;
}