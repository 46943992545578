.business-profile-card .progress-spacing{
    padding: 21px 16px 23px 16px;
}
.business-profile-card .images-label span{
    font-weight: 300;
}
.business-profile-card .upload-file-list .list-group{
    margin-top: 16px;
}
.business-profile-card .upload-file-list .list-group .list-group-item{
    border: 1px solid #3F3968!important;
    border-radius: 8px;
    padding: 8px 16px 8px 8px;
    margin-bottom: 12px;
    margin-top: 0!important;
}
.business-profile-card .upload-file-list .list-group .list-group-item img:nth-of-type(1){
    object-fit: cover;
    margin-right: 12px;
    border-radius: 7px;
    height: 50px;
    width: 50px;
}
.business-profile-card .upload-file-list .list-group .list-group-item img:nth-of-type(2){
    margin-top: 0!important;
    line-height: 150px;
    position: absolute;
    right: 16px;
    top: 45%;
}
.business-profile-card .youtube-label{
    margin-top: 20px;
    margin-bottom: -15px;
}
.business-profile-card .youtube-label label span{
    font-weight: 300;
}
.business-profile-card .youtube-label div label{
    display: none;
}
.business-profile-card .progress-wrapper{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 16px 48px 30px 48px!important;
}
.business-profile-card .progress-wrapper .progress{
    height: 8px;
    border-radius: 8px;
    width: 96.8%;
}
.business-profile-card .progress-wrapper .progress .progress-bar{
    background-color: #00C2FF;
    border-radius: 8px;
}
.business-profile-card .progress-wrapper .progress-value{
    margin-left: 6px;
    color: #52FF9A;
    font-size: 14px;
    font-weight: 500;
}
.business-profile-card .progress-wrapper .form-subtitle{
    font-size: 18px;
    line-height: 28px;;
    margin-bottom: 12px;
}
.business-profile-card .file-input-picker-rounded{
    margin-bottom: 24px;
    cursor: pointer;
    position: relative;
}
/* .business-profile-card .file-input-picker-rounded[data-selected="1"]::before{
    content: "Change logo";
    background-color: rgba(0,0,0,0.5);
    position: absolute;
    left: 0;
    top: 0;
    width: 150px;
    height: 150px;
    border-radius: 100%;
    color: rgba(255,255,255,1);
    font-weight: 500;
    line-height: 150px;
    text-align: center;
    padding-top: 17px;
    font-size: 14px;
} */
/* .business-profile-card .file-input-picker-rounded[data-selected="1"] img{
    display: none;
} */
/* .business-profile-card .file-input-picker-rounded[data-selected="1"]::after{
    content:"";
    position: absolute;
    background: url('/public/assets/images/white-edit.svg') no-repeat center;
    width: 40px;
    height: 40px;
    bottom: 0;
    right: 11px;
    background-color: #0f0742;
    border-radius: 100%;
    background-size: initial;
    padding: 10px;
    box-shadow: 0 4px 10px 0 rgb(0 0 0 / 30%); 
} */
.business-profile-card .file-input-picker-rounded[data-selected="1"] span{
    display: none;
}
.business-profile-card .file-input-picker-rounded img{
    position: absolute;
    width: 40px;
    height: 40px;
    bottom: 0;
    right: 11px;
    background-color: #ffffff;
    border-radius: 100%;
    object-fit: none;
    padding: 5px;
    box-shadow: 0 4px 10px 0 rgb(0 0 0 / 30%);
}
.business-profile-card .form-subtitle{
    margin-top: 8px;
    display: inline-block;
}
.business-profile-card .opening-hours .title-block{
    margin: 26px 0;
}
.business-profile-card .opening-hours .opening-hours-content{
    margin-bottom: 6px;
}
.business-profile-card .opening-hours .opening-hours-content label{
    display: none;
}
.business-profile-card .opening-hours .form-switch.toggle-switch .form-check-input{
    width: 44px!important;
    height: 24px!important;
    border-radius: 12px!important;
}
.business-profile-card .opening-hours .form-switch.toggle-switch .form-check-input:checked{
    background-color: #0F0742;
    border-color: #0F0742;
    background-image:url("/public/assets/images/switch-circle.svg")!important;
}
.business-profile-card .opening-hours .title-name{
    font-weight: 500;
}
.business-profile-card .opening-hours .day-name{
    vertical-align: sub;
    font-weight: 500;
    color: #667085;
}
.business-profile-card .opening-hours .form-check-input{
    border-radius: 6px!important;
    height: 20px!important;
    width: 20px!important
}
.business-profile-card .opening-hours .form-check-input:checked{
    background-color: #E7E6EC;
    border-color: #0F0742;  
}
.business-profile-card .form-check-input:checked[type=checkbox] {
    background-image: url("/public/assets/images/tick-mark.svg")!important;
}
.business-profile-card .card-body .input-group .with-icon-left{
    padding-left: 0!important;
}
.opening-hours .wc-20{
    width: 20%;
}
.opening-hours .wc-35{
    width: 35%;
}
.opening-hours .wc-10{
    width: 10%;
}
.youtube-url-text{
    font-size: 14px;
    line-height: 20px;
    color: #667085;
    line-height: 2;
}
.css-1s2u09g-control {
  border-radius: 8px !important;
  min-height: 44px!important;
  height: 44px;
}
.app-select > div{
    min-height: 44px;
}
.css-14el2xx-placeholder {
  padding: 7px 8px;
}
.business-profile-card
  .input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px;
}
.w-70 {
    width: 70%;
}
.w-71{
    width: 71%;
}

.info-text-file-upload {
  color: #667085;
}

@media screen and (min-width:1441px) and (max-width:1919px){
    .business-profile-card .progress-wrapper .progress{
        width: 95.3%;
    }
}
@media screen and (max-width:1440px){
    .business-profile-card .progress-wrapper .progress{
        width: 94.5%;
    }
}
@media screen and (max-width:1024px){
    .business-profile-card .progress-wrapper .progress{
        width: 94%;
    }
}
@media screen and (max-width:767px){
    .business-profile-card .progress-wrapper{
        padding: 16px 16px 30px 16px!important;
    }
    .business-profile-card .progress-wrapper .progress {
        width: 86%;
    }
    .business-profile-card .file-input-picker-rounded{
        margin: 0 auto;
    }
    .business-profile-card .opening-hours .opening-hours-content{
        flex-direction: column;
    }
    .opening-hours .opening-hours-content .wc-35{
        width: 100%;
    }
    .opening-hours .opening-hours-content .wc-20{
        width: 100%;
    }
    .close-input.w-71{
        width: 100%;
    }
}
