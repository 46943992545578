.page-header{
  display: flex;
  justify-content: space-between;
}
.page-header.breadcrum-spacing{
  margin-bottom: 43px;
}
.header-right .notification-area button{
  background-color: #F2F4F7;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 100%;
  border: 0;
}
.page-header .title {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.02em;
  color: #0f0742;
}

.page-header a {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
  text-decoration: none;
}
.bg-orange{
  background-color: #FF8F50;
  font-weight: 300;
}
@media screen and (max-width:991px){
  .page-header.breadcrum-spacing{
    margin-bottom: 15px;
  }
}
@media screen and (max-width:767px){
  .page-header.breadcrum-spacing{
    margin-bottom:10px
  }
  .page-header .header-right .notification-area{
    position: fixed;
    top: 15px;
    z-index: 1;
    right: 65px;
  }
  .header-right .notification-area button{
    height: 30px;
    width: 30px;
    line-height: 29px;
  }
  .header-right .notification-area button img{
    height: 15px;
    line-height: 15px;
  }
}
