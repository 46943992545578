.onboarding-step-bar{
  background-color: #0f0742;
  padding: 48px;
}
.signin-sidebar-info{
  height: 100%;
  background-color: #0f0742;
  padding: 48px;
}

.onboarding-step-bar .logo,
.signin-sidebar-info .logo {
  width: 102px;
}

.merchant-onboarding-container.step-container .onboarding-step-bar .logo {
  width: 50%;
}

.onboarding-step-bar .step-connector {
  width: 2px;
  background: #667085;
  height: 72%;
  margin: auto;
}
.onboarding-step-bar .step-one .step-connector{
  margin-top: 4px;
}
.onboarding-step-bar .completed .step-connector{
  margin-top: 0;
}
.onboarding-step-bar .step-two .completed .step-connector{
  height: 63%;
}
.onboarding-step-bar .step-two .step-connector{
  margin-top: -4px;
  height: 68%;
}
.step-list{
  margin-top: 80px;
}

.step-details {
  padding-left: 16px;
  padding-right: 8px;
  margin-bottom: 36px;
}

.step-details h5{
  font-size: 16px;
  line-height: 24px;
}

.step-details p{
  font-size: 16px;
  margin-top: 2px;
  line-height: 24px;
  color:#D0D5DD;
  margin-bottom: 0;
}

.sidebar-footer {
  background-color: #0f0742;
  width: 480px;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px 32px 32px;
}

.sidebar-footer span{
  color:#D0D5DD;
  font-size: 14px;
}

.sidebar-footer span img{
  margin-right: 5px;
  margin-top: -2px;
}

.onboarding-step-bar.side-menu{
  padding: 16px;
  min-height: 80vh;
  overflow-y: auto;
  height: auto;
}

.onboarding-step-bar.side-menu .logo{
  width: 80%;
  margin-left: 9px;
}
.onboarding-step-bar.side-menu .list-group li{
  padding: 18px 16px;
  margin-bottom: 4px;
}
.onboarding-step-bar.side-menu .list-group li .child-menu li{
  padding: 10px 16px;
}
.onboarding-step-bar.side-menu .list-group li.active{
  background: rgba(255,255,255,0.1);
  border-radius: 6px;
}
.onboarding-step-bar.side-menu .list-group li.active.active-child,
.onboarding-step-bar.side-menu .list-group li.active.active-child .active{
  background-color: transparent;
}

/*Sign In*/
.signin .onboarding-content-card h3,
.signin .onboarding-content-card p{
  text-align: center;
}
.email-verification p{
  margin-bottom: 0;
}
.email-verification .email{
  font-weight: 700;
  color: #667085;
}
.signin-sidebar-info .information{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}
.signin-sidebar-info .information .line-first{
  font-size: 48px;
  line-height: 60px;
  color: #52FF9A;
}

.signin-sidebar-info .information .line-second{
  font-size: 48px;
  line-height: 60px;
  color: #00C2FF;
}

.signin-sidebar-info .information .sub-content{
  line-height: 30px;
  color: #ffffff;
  margin-top: 24px;
}

/*Forgot password*/
.back-link{
  margin-top: 33px;
}
.back-link a,
.back-link a:hover{
  color: #0F0742;
  text-decoration: none;
}
.back-link a img{
  transform: rotate(
    180deg
  );
  margin-top: -2px;
  margin-right: 3px;
}

/*Merchant sidebar*/
.sidebar-menu{
  background-color: #0f0742;
}

.merchant-admin-container .sidebar-bottom .sidebar-info-box{
  background-color: #52FF9A;
  border-radius: 8px;
  margin: 0 16px 24px 0;
  padding: 20px 16px;
}
.merchant-admin-container .sidebar-bottom .sidebar-info-box .feature-icon{
  margin-bottom: 16px;
}
.merchant-admin-container .sidebar-bottom .sidebar-info-box .title{
  font-size: 20px;
  line-height: 25px;
  margin-bottom: 7px;
}
.merchant-admin-container .sidebar-bottom .sidebar-info-box .sub-title{
  font-size: 14px;
  font-weight: 500;
  color: #0F0742;
  opacity: 0.7;
  margin-bottom: 13px;
}
.merchant-admin-container .sidebar-bottom .sidebar-info-box .feature-list {
  margin-top: 8px;
}
.merchant-admin-container .sidebar-bottom .sidebar-info-box .feature-list span{
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: #0C0635;
  margin-left: 9px;
}
.merchant-admin-container .sidebar-bottom .sidebar-footer{
  width: 94%;
  border-top: 1px solid #475467;
  padding: 24px 0;
  /* margin: 0 auto; */
}
.merchant-admin-container .sidebar-bottom .sidebar-footer .logout-img{
  cursor: pointer;
}

/*Responsive design*/
@media screen and (max-width:1300px){
  .step-list{
    margin-top: 30px;
  }
  .onboarding-step-bar{
    padding: 10px;
  }
  .signin-sidebar-info{
    padding: 15px;
  }
  .signin-sidebar-info .information .line-first,
  .signin-sidebar-info .information .line-second{
    font-size: 34px;
    line-height: 44px;
  }
}

@media screen and (max-width:991px){
  .merchant-admin-container .sidebar-bottom .sidebar-footer{
    margin: 0 auto;
    width: 100%;
  }
  .merchant-admin-container .sidebar-bottom .sidebar-info-box{
    margin: 0 0 24px 0;
  }
}

@media screen and (max-width:767px){
  .onboarding-step-bar{
    min-height: auto;
  }
  .sidebar-footer{
    width: 100%;
  }
  .signin-sidebar-info{
    padding: 30px;
    height: auto;
    min-height: auto;
  }
  .signin-sidebar-info .logo{
    margin-bottom: 30px;
  }
  .merchant-admin-container .sidebar-bottom .sidebar-info-box{
    margin: 0 16px 24px 16px;
  }
  .merchant-admin-container .sidebar-bottom .sidebar-footer{
    width: 100%;
    margin: 0 auto;
  }
}