.merchant-list-wrapper{
    border: 1px solid #E4E7EC;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    border-radius: 8px;
    background-color: #fff;
}
.merchant-list-wrapper .merchant-list-header{
    padding: 20px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom:1px solid #E4E7EC;
}
.merchant-list-wrapper .merchant-list-header .header-title{
    display: flex;
    align-items: center;
}
.merchant-list-wrapper .merchant-list-header .header-title .image img{
    margin-right: 16px;
}
.merchant-list-wrapper .merchant-list-header .header-title .text{
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
}
.merchant-list-wrapper .merchant-list-header .header-title .text > div{
    text-align: center;
}
.merchant-list-wrapper .merchant-list-header .header-title .text .user-count{
    font-size: 12px;
    font-weight: 500;
    background-color: #E7E6EC;
    padding: 2px 8px;
    border-radius: 16px;
    line-height: 18px;
}
.merchant-list-wrapper .merchant-list-header .header-action{
    display: flex;
    align-items: center;
    justify-content: center;
}
.merchant-list-wrapper .merchant-list-header .header-action .search-box{
    position: relative;
}
.merchant-list-wrapper .merchant-list-header .header-action .search-box div label{
    display: none;
}
.merchant-list-wrapper .merchant-list-header .header-action .search-box input{
    width: 320px;
    padding: 10px 14px 10px 42px!important;
}
.merchant-list-wrapper .merchant-list-header .header-action .search-box:before{
    position: absolute;
    content: "";
    background: url('/public/assets/images/search.svg') no-repeat center;
    left: 16px;
    top: 14px;
    width: 17px;
    height: 17px;
    z-index: 4;
}
.merchant-list-wrapper .merchant-list-header .header-action .filter-btn-wrapper{
    margin-right: 8px;
    margin-left: 24px;
}
.merchant-list-wrapper .merchant-list-header .header-action .filter-btn-wrapper > div,
.merchant-list-wrapper .merchant-list-header .header-action .download-btn-wrapper > div{
    margin-top: 0!important;
}
.merchant-list-wrapper .merchant-list-header .header-action .filter-btn-wrapper button,
.merchant-list-wrapper .merchant-list-header .header-action .download-btn-wrapper button{
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    padding: 10px 16px 10px 44px!important;
    color: #344054;
    font-size: 14px;
    position: relative;
}
.merchant-list-wrapper .merchant-list-header .header-action .download-btn-wrapper button{
    padding: 10px 35px 10px 44px!important;
}
.merchant-list-wrapper .merchant-list-header .header-action .download-btn-wrapper{
    position: relative;
}
.merchant-list-wrapper .merchant-list-header .header-action .download-btn-wrapper::after{
    position: absolute;
    content: "";
    right: 18px;
    top: 20px;
    width: 7px;
    height: 4px;
    background: url('/public/assets/images/download-arrow.svg') no-repeat center;
}
.merchant-list-wrapper .merchant-list-header .header-action .filter-btn-wrapper button::before{
    position: absolute;
    content: "";
    background: url('/public/assets/images/filter.svg') no-repeat center;
    left: 18px;
    top: 15px;
    width: 15px;
    height: 12px;
}
.merchant-list-wrapper .merchant-list-header .header-action .download-btn-wrapper button::before{
    position: absolute;
    content: "";
    background: url('/public/assets/images/download.svg') no-repeat center;
    left: 17px;
    top: 12px;
    width: 17px;
    height: 17px;
}
.merchant-list-wrapper .merchant-list-header .header-action .filter-btn-wrapper button:hover,
.merchant-list-wrapper .merchant-list-header .header-action .download-btn-wrapper button:hover{
    color: #344054;
    background: #F9FAFB; 
}
.merchant-list-wrapper .merchant-list-header .header-action .download-btn-wrapper .download-dropdown-list{
    position: absolute;
    z-index: 2;
    background-color: #fff;
    width: 148px;
    box-shadow: 0px 12px 16px -4px rgb(16 24 40 / 10%), 0px 4px 6px -2px rgb(16 24 40 / 5%);
    border-radius: 8px;
}
.merchant-list-wrapper .merchant-list-header .header-action .download-btn-wrapper .download-dropdown-list ul{
    margin-bottom: 0;
    padding: 0;
}
.merchant-list-wrapper .merchant-list-header .header-action .download-btn-wrapper .download-dropdown-list ul li{
    list-style: none;
    padding: 12px 17px 12px 42px;
    position: relative;
}
.merchant-list-wrapper .merchant-list-header .header-action .download-btn-wrapper .download-dropdown-list ul li.csv-link::before{
    position: absolute;
    content: "";
    background: url('/public/assets/images/csv.svg') no-repeat center;
    top: 16px;
    left: 17px;
    width: 17px;
    height: 19px;
}
.merchant-list-wrapper .merchant-list-header .header-action .download-btn-wrapper .download-dropdown-list ul li.pdf-link::before{
    position: absolute;
    content: "";
    background: url('/public/assets/images/pdf.svg') no-repeat center;
    top: 16px;
    left: 17px;
    width: 17px;
    height: 19px;
}
.merchant-list-wrapper .merchant-list-header .header-action .download-btn-wrapper .download-dropdown-list ul li:hover{
    background: #F9FAFB;
    border-radius: 8px; 
}
.merchant-list-wrapper .merchant-list-header .header-action .download-btn-wrapper .download-dropdown-list ul li a{
    color: #0F0742;
    font-weight: 300;
    text-decoration: none;
    display: block;
}
.merchant-list-wrapper .rdt_Table .rdt_TableHead .rdt_TableHeadRow{
    background: #F9FAFB; 
}
.merchant-list-wrapper .rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol .rdt_TableCol_Sortable{
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #667085;
}
.merchant-list-wrapper .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell{
    padding: 16px;
}
.merchant-list-wrapper .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell div{
    font-size: 14px;
    font-weight: 300;
    color: #667085;
}
.merchant-list-wrapper .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell:nth-of-type(1) div .name{
    font-weight: 500;
    color: #101828;
}
.merchant-list-wrapper .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell:nth-of-type(4) div .time-zone{
    color: #101828;
    font-weight: 400;
}
.merchant-list-wrapper .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell .delete-icon{
    margin: 0 28px;
}
.merchant-list-wrapper .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell .active-status{
    padding: 2px 8px 2px 20px;
    background-color: #ECFDF3;
    font-size: 12px;
    font-weight: 500;
    color: #027A48;
    border-radius: 16px;
    position: relative;
}
.merchant-list-wrapper .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell .active-status::before {
    content: "";
    position: absolute;
    left: 7px;
    top: 8px;
    width: 6px;
    height: 6px;
    border-radius: 100%;
    /* z-index: 999999; */
    background-color: #027A48;
}
.merchant-list-wrapper .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell .frozen-status{
    padding: 2px 8px 2px 20px;
    background: rgba(0, 194, 255, 0.1);
    font-size: 12px;
    font-weight: 500;
    color: #00C2FF;
    border-radius: 16px;
    position: relative;
}
.merchant-list-wrapper .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell .frozen-status::before {
    content: "";
    position: absolute;
    left: 7px;
    top: 8px;
    width: 6px;
    height: 6px;
    border-radius: 100%;
    /* z-index: 999999; */
    background-color: #00C2FF;
}
.merchant-list-wrapper .rdt_Pagination{
    background-color: transparent;
    position: relative;
}
.merchant-list-wrapper .rdt_Pagination #pagination-first-page,
.merchant-list-wrapper .rdt_Pagination #pagination-last-page{
    display:none
}
.filter-modal.merchant-list-filter-modal .rdrDefinedRangesWrapper{
    display: none;
}
.merchant-list-filter-modal .user-filter{
    margin-top: 15px;
    border-top: 1px solid #e9e9e9;
    padding-top: 10px;
}
.merchant-list-filter-modal .user-filter .common-title{
    margin-bottom: 8px;
}
.merchant-list-filter-modal .user-filter-list .form-label{
    display: none;
}
.merchant-list-filter-modal .user-filter-list .input-group{
    margin-bottom: 8px;
}
.merchant-list-filter-modal .user-filter-list .input-group input,
.merchant-list-filter-modal .user-filter-list .input-group select,
.merchant-list-filter-modal .user-filter-list .input-group input::placeholder,
.merchant-list-filter-modal .user-filter-list .input-group select::placeholder{
    color: #101828;
}
.merchant-list-filter-modal .user-filter-list .css-14el2xx-placeholder{
    padding: 7px 14px!important;
}
.merchant-list-filter-modal .modal-footer{
    margin-top: 32px;
}
.delete-merchant-list-modal .modal-footer {
    display: flex!important;
    justify-content: space-between!important;
}
.delete-merchant-list-modal .modal-footer div {
    width: 48%;
}
.merchant-list-edit-wrapper .main-content-wrapper .top-profile-info{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
}
.merchant-list-edit-wrapper .main-content-wrapper .top-profile-info .user-score{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
}
.merchant-list-edit-wrapper .main-content-wrapper .top-profile-info .user-score .blue-box{
    background: #0F0742;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    border-radius: 14px;
    width: 163px;
    min-height: 128px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 5px;
}
.merchant-list-edit-wrapper .main-content-wrapper .top-profile-info .user-score .blue-box p{
    font-size: 12px;
    color: rgba(255,255,255,0.8);
    line-height: 20px;
    font-weight: 500;
    margin: 4px 0 8px 0;
}
.merchant-list-edit-wrapper .main-content-wrapper .top-profile-info .user-score .blue-box .count{
    background-color: #ffffff;
    padding: 10px 14px;
    border-radius: 8px;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
    width: 123px;
    min-height: 44px;
    color: #667085;
}
.merchant-list-edit-wrapper .main-content-wrapper .top-profile-info .user-score .blue-box .count span{
    font-weight: 700;
    color: #101821;
    margin-left: 8px;
}
.merchant-list-wrapper .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell .merchant-detail{
    display: flex;
}
.merchant-list-wrapper .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell .merchant-detail .merchant-profile{
    margin-right: 10px;
}
.merchant-list-wrapper .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell .merchant-detail .merchant-profile img{
    border-radius: 100%;
}
.merchant-list-wrapper .action-btn-wrapper .pause-icon .tooltip-custom{
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition: all ease-in 0.2s;
    padding: 8px 12px;
    background: #101828;
    border-radius: 8px;
    left: 11px;
    top: -16px;
    color: #ffffff;
    text-decoration: none;
}
.merchant-list-wrapper .action-btn-wrapper .pause-icon .tooltip-custom::after{
    content: "";
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid #101828;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -7px;
    margin: 0 auto;
    width: 7px;
}
.merchant-list-wrapper .pause-icon:hover .tooltip-custom{
    opacity: 1;
    visibility: visible;
    z-index: 1;
}
.merchant-edit-wrapper .wrapper-padding.pt-0{
    padding-top: 48px!important;
}
.merchant-list-wrapper .view-deal a{
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #0C0635;
    text-transform: capitalize;
}
/*view deal*/
.merchant-view-deal-wrapper .dashboard-wrapper .deal-info .deal-info-card .card-body .deal-info-content .text-content .top-info .edit-profile{
    display: none;
}
.merchant-view-deal-wrapper .view-deal-wrapper{
    background-color: #fff;
    padding: 32px 16px;
    border: 1px solid #E4E7EC;
    box-shadow: 0px 4px 8px -2px rgb(16 24 40 / 10%), 0px 2px 4px -2px rgb(16 24 40 / 6%);
    border-radius: 16px;
}
.merchant-view-deal-wrapper .view-deal-wrapper .deal-top-bar .search-deal-box .input-group{
    position: relative;
    width: 320px;
}
.merchant-view-deal-wrapper .view-deal-wrapper .deal-top-bar .search-deal-box label{
    display: none;
}
.merchant-view-deal-wrapper .view-deal-wrapper .deal-top-bar .search-deal-box input{
    padding: 10px 14px 10px 42px!important;
}
.merchant-view-deal-wrapper .view-deal-wrapper .deal-top-bar .search-deal-box .input-group::before{
    content: "";
    position: absolute;
    left: 17px;
    top: 14px;
    background: url('../../../../../public/assets/images/search.svg') no-repeat center;
    width: 17px;
    height: 17px;
    z-index: 22;
}
.pagination-wrapper .pagination{
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;
    margin-bottom: 3px;
}
.pagination-wrapper .pagination .center-content{
    display: flex;
}
.pagination-wrapper .pagination .arrow-btn .visually-hidden{
    position: initial!important;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
}
.pagination-wrapper .pagination .page-item.arrow-btn .page-link{
    width: auto;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 48px;
    line-height: 1;
}
.pagination-wrapper .pagination .page-item.arrow-btn .page-link span:nth-of-type(1){
    display: none;
}
.pagination-wrapper .pagination .page-item.arrow-btn.previous-btn .page-link{
    padding: 7px 14px 7px 42px;
    position: relative;
}
.pagination-wrapper .pagination .page-item.arrow-btn.next-btn .page-link{
    padding: 7px 42px 7px 14px;
    position: relative;
}
.pagination-wrapper .pagination .page-item.arrow-btn.previous-btn .page-link::after{
    content: "";
    position: absolute;
    background: url('../../../../../public/assets/images/right_arrow.png') no-repeat center;
    left: 15px;
    top: 10px;
    width: 14px;
    height: 14px;
    transform: rotate(180deg);
}
.pagination-wrapper .pagination .page-item.arrow-btn.next-btn .page-link::after{
    content: "";
    position: absolute;
    background: url('../../../../../public/assets/images/right_arrow.png') no-repeat center;
    right: 15px;
    top: 10px;
    width: 14px;
    height: 14px;
}
.pagination-wrapper .pagination .page-item{
    margin: 0 2px;
}
.pagination-wrapper .pagination .page-item .page-link{
    border: 0;
    line-height: 1.6;
    min-width: 40px;
    height: 36px;
    font-weight: 500;
    color: #667085;
    text-align: center;
    border-radius: 8px
}
.pagination-wrapper .pagination .page-item.active .page-link{
    background-color: #E4E7EC!important;
    color: #000000!important;
}
.pagination-wrapper .pagination .page-link:hover {
    z-index: 2;
    background-color: #E4E7EC;
    color: #667085;
}
.user-image {
    height: 30px;
    width: 30px;
    object-fit: contain;
    border-radius: 15px;
    margin-right: 12px;
}

.app-select .app-select-option__option--is-selected > div::after {
    content: '';
    background: url("/public/assets/images/checked.png") no-repeat;
    top: 16px;
    right: 12px;
    position: absolute;
    display: inline-block;
    height: 12px;
    width: 12px;
    background-size: contain;
}

.app-select .app-select-option__option--is-selected {
    background-color: #F3F3F6 !important;
    position: relative;
}

/*merchant category*/
.merchant-category-wrapper .search-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 26px;
}
.merchant-category-wrapper .search-header .search-box {
    width: 320px;
    position: relative;
}
.merchant-category-wrapper .search-header .search-box::before{
    position: absolute;
    content: "";
    background: url('/public/assets/images/search.svg') no-repeat center;
    left: 16px;
    top: 14px;
    width: 17px;
    height: 17px;
    z-index: 4;
}
.merchant-category-wrapper .search-header .search-box input{
    padding: 10px 14px 10px 44px!important;
}
.merchant-category-wrapper .search-header .search-box label{
    display: none;
}
.merchant-category-wrapper .search-header .filter-option .css-1s2u09g-control,
.merchant-category-wrapper .search-header .filter-option .css-1pahdxg-control{
    border-radius: 100px!important;
}
.merchant-category-wrapper .category-list{
    display: flex;
    flex-wrap: wrap;
    gap:16px
}
.merchant-category-wrapper .category-list .category-box{
    background-color: #ffffff;
    border:1px solid #E4E7EC;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 18px;
    flex: 0 0 24%
}
.merchant-category-wrapper .category-list .category-box .image-wrapper{
    height: 150px; 
    position: relative;
}
.merchant-category-wrapper .category-list .category-box .image-wrapper img.category-img{
    height: 150px;
    object-fit: cover;
    width: 100%;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}
.merchant-category-wrapper .category-list .category-box .image-wrapper .edit-icon-wrapper{
    cursor: pointer;
    position: absolute;
    right: 12px;
    top: 12px;
    background-color: #ffffff;
    height: 27px;
    width: 27px;
    border-radius: 100%;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    text-align: center;
}
.merchant-category-wrapper .category-list .category-box .image-wrapper .edit-icon-wrapper img{
    width: 14px;
    height: 13px;
}
.merchant-category-wrapper .category-list .category-box  .category-name{
    font-size: 18px;
    line-height: 30px;
    font-weight: 700;
    padding: 16px;
}

@media screen and (max-width:1600px){
    .merchant-category-wrapper .category-list .category-box{
        flex: 0 0 23.7%;
    }
}

@media screen and (min-width:1025px) and (max-width:1199px){
    .merchant-list-wrapper .merchant-list-header{
        flex-direction: column;
        align-items: flex-start;
    }
    .merchant-list-wrapper .merchant-list-header .header-action .search-box{
        margin: 15px 0 0;
    }
    .merchant-list-wrapper .merchant-list-header .header-action .filter-btn-wrapper,
    .merchant-list-wrapper .merchant-list-header .header-action .download-btn-wrapper{
        margin-top: 15px;
    }
}

@media screen and (max-width:1024px){
    .merchant-list-wrapper .merchant-list-header{
        flex-direction: column;
        align-items: flex-start;
    }
    .merchant-list-wrapper .merchant-list-header .header-action{
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .merchant-list-wrapper .merchant-list-header .header-action .search-box{
        width: auto;
        margin: 15px 0 0;
    }
    .merchant-list-wrapper .merchant-list-header .header-action .search-box input{
        width: auto;
    }
    .merchant-list-wrapper .merchant-list-header .header-action .filter-btn-wrapper,
    .merchant-list-wrapper .merchant-list-header .header-action .download-btn-wrapper{
        margin-top: 15px;
    }
    .merchant-category-wrapper .category-list .category-box {
        flex: 0 0 23.3%;
    }
}

@media screen and (max-width:991px){
    .merchant-list-wrapper .merchant-list-header .header-action{
        width: 100%;
    }
    .merchant-list-wrapper .merchant-list-header .header-action .search-box{
        width: 60%;
        margin: 15px 15px 15px 0;
    }
    .merchant-list-wrapper .merchant-list-header .header-action .search-box input{
        width: auto;
    }
    .merchant-list-wrapper .merchant-list-header .header-action .filter-btn-wrapper{
        margin-left: 0;
    }
    .merchant-list-wrapper .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell .delete-icon{
        margin: 0 12px;
    }
    .merchant-list-wrapper .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell .merchant-detail{
        flex-wrap: wrap;
    }
}
@media screen and (max-width:767px){
    .merchant-list-wrapper .merchant-list-header .header-action .search-box{
        width: 100%;
        margin: 15px 0;
    }
    .merchant-list-wrapper .merchant-list-header .header-action .filter-btn-wrapper,
    .merchant-list-wrapper .merchant-list-header .header-action .download-btn-wrapper{
        margin-top: 0;
    }
    .merchant-admin-content .deal-top-bar{
        flex-wrap: wrap;
    }
    .merchant-admin-content .deal-top-bar .search-deal-box{
        margin-bottom: 15px;
    }
    .pagination-wrapper .pagination{
        flex-direction: column;
    }
    .merchant-category-wrapper .category-list .category-box {
        flex: 0 0 100%;
    }
    .merchant-category-wrapper .search-header{
        flex-direction: column;
    }
    .merchant-category-wrapper .search-header .search-box{
        margin-bottom: 15px;
    }
}