.suggested-business-list-wrapper{
    border: 1px solid #E4E7EC;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    border-radius: 8px;
    background-color: #fff;
}
.suggested-business-list-wrapper .suggested-business-list-header{
    padding: 20px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom:1px solid #E4E7EC;
}
.suggested-business-list-wrapper .suggested-business-list-header .header-title{
    display: flex;
    align-items: center;
}
.suggested-business-list-wrapper .suggested-business-list-header .header-title .image img{
    margin-right: 16px;
}
.suggested-business-list-wrapper .suggested-business-list-header .header-title .text{
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
}
.suggested-business-list-wrapper .suggested-business-list-header .header-title .text > div{
    text-align: center;
}
.suggested-business-list-wrapper .suggested-business-list-header .header-action{
    display: flex;
    align-items: center;
    justify-content: center;
}
.suggested-business-list-wrapper .suggested-business-list-header .header-action .search-box{
    position: relative;
}
.suggested-business-list-wrapper .suggested-business-list-header .header-action .search-box div label{
    display: none;
}
.suggested-business-list-wrapper .suggested-business-list-header .header-action .search-box input{
    width: 320px;
    padding: 10px 14px 10px 42px!important;
}
.suggested-business-list-wrapper .suggested-business-list-header .header-action .search-box:before{
    position: absolute;
    content: "";
    background: url('/public/assets/images/search.svg') no-repeat center;
    left: 16px;
    top: 14px;
    width: 17px;
    height: 17px;
    z-index: 4;
}
.suggested-business-list-wrapper .rdt_Table .rdt_TableHead .rdt_TableHeadRow{
    background: #F9FAFB; 
}
.suggested-business-list-wrapper .rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol .rdt_TableCol_Sortable{
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #667085;
}
.suggested-business-list-wrapper .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell{
    padding: 16px;
}
.suggested-business-list-wrapper .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell div{
    font-size: 14px;
    font-weight: 300;
    color: #667085;
}
.suggested-business-list-wrapper .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell:nth-of-type(2) div{
    font-weight: 500;
    color: #101828;
}
.suggested-business-list-wrapper .rdt_Pagination{
    background-color: transparent;
    position: relative;
}
.suggested-business-list-wrapper .rdt_Pagination #pagination-first-page,
.suggested-business-list-wrapper .rdt_Pagination #pagination-last-page{
    display:none
}
.suggested-business-list-wrapper .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell .suggested-business-detail{
    display: flex;
}
.suggested-business-list-wrapper .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell .suggested-business-detail .suggested-business-profile{
    margin-right: 10px;
}
.suggested-business-list-wrapper .user-detail{
    display: flex;
    align-items: center;
    justify-content: center;
}
.suggested-business-list-wrapper .user-detail img{
    margin-right: 12px;
    border-radius: 100%;
}

@media screen and (min-width:1025px) and (max-width:1199px){
    .suggested-business-list-wrapper .suggested-business-list-header{
        flex-direction: column;
        align-items: flex-start;
    }
    .suggested-business-list-wrapper .suggested-business-list-header .header-action .search-box{
        margin: 15px 0 0;
    }
}

@media screen and (max-width:1024px){
    .suggested-business-list-wrapper .suggested-business-list-header .header-action{
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .suggested-business-list-wrapper .suggested-business-list-header .header-action .search-box{
        width: auto;
        margin: 15px 0 0;
    }
    .suggested-business-list-wrapper .suggested-business-list-header .header-action .search-box input{
        width: auto;
    }
}

@media screen and (max-width:991px){
    .suggested-business-list-wrapper .suggested-business-list-header .header-action .search-box{
        width: 100%;
        margin: 15px 0;
    }
    .suggested-business-list-wrapper .suggested-business-list-header .header-action .search-box input{
        width: auto;
    }
    .admin-sidebar-menu .side-menu .menu-items .list-group .list-group-item:last-child {
        bottom: 14%;
    }
}

@media screen and (max-width:767px){
    .suggested-business-list-wrapper .suggested-business-list-header {
        flex-direction: column;
        align-items: flex-start;
    }
    .admin-sidebar-menu .side-menu .menu-items .list-group .list-group-item:last-child {
        bottom: 18%;
    }
}