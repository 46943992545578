.upload-file-modal .modal-header{
    display: block;
    border-bottom: 0;
}
.upload-file-modal .heading{
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 8px;
}
.upload-file-modal .sub-heading{
    font-size: 14px;
    line-height: 20px;
    color: #667085;
}
.upload-file-modal .category-name{
    font-size: 18px;
    margin: 20px 0 0;
    font-weight: 700;
    line-height: 30px;
    font-family: 'Quicksand';
}
.upload-file-modal .modal-footer{
    border-top: 0;
    display: flex!important;
}
.upload-file-modal .modal-footer > div{
    margin: 0 12px 0 0!important;
    width: 48.6%;
}
.upload-file-modal .modal-footer div:nth-of-type(2) {
    margin-right: 0!important;
}
.upload-file-modal .upload-content-wrapper{
    margin: 20px 0 16px 0;
}
@media screen and (max-width:767px){
    .upload-file-modal .modal-footer > div{
        width:48%;
    }
}
@media (min-width: 576px){
    .upload-file-modal .modal-dialog {
        max-width: 480px!important;
    }
}

.preview-image-container {
    border-radius: 8px;
    border-bottom-right-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
}