.marketplace-transaction-wrapper {
  border: 1px solid #e4e7ec;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  border-radius: 8px;
  background-color: #fff;
}
.marketplace-transaction-wrapper .marketplace-transaction-header {
  padding: 20px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e4e7ec;
}
.marketplace-transaction-wrapper .marketplace-transaction-header .header-title {
  display: flex;
  align-items: center;
}
.marketplace-transaction-wrapper .marketplace-transaction-header .header-title .image img {
  margin-right: 16px;
}
.marketplace-transaction-wrapper .marketplace-transaction-header .header-title .text {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}
.marketplace-transaction-wrapper .marketplace-transaction-header .header-action {
  display: flex;
  align-items: center;
  justify-content: center;
}
.marketplace-transaction-wrapper .marketplace-transaction-header .header-action .search-box {
  position: relative;
}
.marketplace-transaction-wrapper
  .marketplace-transaction-header
  .header-action
  .search-box
  div
  label {
  display: none;
}
.marketplace-transaction-wrapper .marketplace-transaction-header .header-action .search-box input {
  width: 320px;
  padding: 10px 14px 10px 42px !important;
}
.marketplace-transaction-wrapper .marketplace-transaction-header .header-action .search-box:before {
  position: absolute;
  content: '';
  background: url('/public/assets/images/search.svg') no-repeat center;
  left: 16px;
  top: 14px;
  width: 17px;
  height: 17px;
  z-index: 4;
}
.marketplace-transaction-wrapper
  .marketplace-transaction-header
  .header-action
  .filter-btn-wrapper {
  margin-right: 8px;
  margin-left: 24px;
}
.marketplace-transaction-wrapper
  .marketplace-transaction-header
  .header-action
  .filter-btn-wrapper
  > div,
.marketplace-transaction-wrapper
  .marketplace-transaction-header
  .header-action
  .download-btn-wrapper
  > div {
  margin-top: 0 !important;
}
.marketplace-transaction-wrapper
  .marketplace-transaction-header
  .header-action
  .filter-btn-wrapper
  button,
.marketplace-transaction-wrapper
  .marketplace-transaction-header
  .header-action
  .download-btn-wrapper
  button {
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  padding: 10px 16px 10px 44px !important;
  color: #344054;
  font-size: 14px;
  position: relative;
}
.marketplace-transaction-wrapper
  .marketplace-transaction-header
  .header-action
  .download-btn-wrapper
  button {
  padding: 10px 35px 10px 44px !important;
}
.marketplace-transaction-wrapper
  .marketplace-transaction-header
  .header-action
  .download-btn-wrapper {
  position: relative;
}
.marketplace-transaction-wrapper
  .marketplace-transaction-header
  .header-action
  .download-btn-wrapper::after {
  position: absolute;
  content: '';
  right: 18px;
  top: 20px;
  width: 7px;
  height: 4px;
  background: url('/public/assets/images/download-arrow.svg') no-repeat center;
}
.marketplace-transaction-wrapper
  .marketplace-transaction-header
  .header-action
  .filter-btn-wrapper
  button::before {
  position: absolute;
  content: '';
  background: url('/public/assets/images/filter.svg') no-repeat center;
  left: 18px;
  top: 15px;
  width: 15px;
  height: 12px;
}
.marketplace-transaction-wrapper
  .marketplace-transaction-header
  .header-action
  .download-btn-wrapper
  button::before {
  position: absolute;
  content: '';
  background: url('/public/assets/images/download.svg') no-repeat center;
  left: 17px;
  top: 12px;
  width: 17px;
  height: 17px;
}
.marketplace-transaction-wrapper
  .marketplace-transaction-header
  .header-action
  .filter-btn-wrapper
  button:hover,
.marketplace-transaction-wrapper
  .marketplace-transaction-header
  .header-action
  .download-btn-wrapper
  button:hover {
  color: #344054;
  background: #f9fafb;
}
.marketplace-transaction-wrapper
  .marketplace-transaction-header
  .header-action
  .download-btn-wrapper
  .download-dropdown-list {
  position: absolute;
  z-index: 2;
  background-color: #fff;
  width: 148px;
  box-shadow: 0px 12px 16px -4px rgb(16 24 40 / 10%), 0px 4px 6px -2px rgb(16 24 40 / 5%);
  border-radius: 8px;
}
.marketplace-transaction-wrapper
  .marketplace-transaction-header
  .header-action
  .download-btn-wrapper
  .download-dropdown-list
  ul {
  margin-bottom: 0;
  padding: 0;
}
.marketplace-transaction-wrapper
  .marketplace-transaction-header
  .header-action
  .download-btn-wrapper
  .download-dropdown-list
  ul
  li {
  list-style: none;
  padding: 12px 17px 12px 42px;
  position: relative;
}
.marketplace-transaction-wrapper
  .marketplace-transaction-header
  .header-action
  .download-btn-wrapper
  .download-dropdown-list
  ul
  li.csv-link::before {
  position: absolute;
  content: '';
  background: url('/public/assets/images/csv.svg') no-repeat center;
  top: 16px;
  left: 17px;
  width: 17px;
  height: 19px;
}
.marketplace-transaction-wrapper
  .marketplace-transaction-header
  .header-action
  .download-btn-wrapper
  .download-dropdown-list
  ul
  li.pdf-link::before {
  position: absolute;
  content: '';
  background: url('/public/assets/images/pdf.svg') no-repeat center;
  top: 16px;
  left: 17px;
  width: 17px;
  height: 19px;
}
.marketplace-transaction-wrapper
  .marketplace-transaction-header
  .header-action
  .download-btn-wrapper
  .download-dropdown-list
  ul
  li:hover {
  background: #f9fafb;
  border-radius: 8px;
}
.marketplace-transaction-wrapper
  .marketplace-transaction-header
  .header-action
  .download-btn-wrapper
  .download-dropdown-list
  ul
  li
  a {
  color: #0f0742;
  font-weight: 300;
  text-decoration: none;
  display: block;
}
.marketplace-transaction-wrapper .rdt_Table .rdt_TableHead .rdt_TableHeadRow {
  background: #f9fafb;
}
.marketplace-transaction-wrapper
  .rdt_Table
  .rdt_TableHead
  .rdt_TableHeadRow
  .rdt_TableCol
  .rdt_TableCol_Sortable {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #667085;
}
.marketplace-transaction-wrapper .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell {
  padding: 16px;
}
.marketplace-transaction-wrapper .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell div {
  font-size: 14px;
  font-weight: 300;
}
.marketplace-transaction-wrapper
  .rdt_Table
  .rdt_TableBody
  .rdt_TableRow
  .rdt_TableCell:nth-of-type(1)
  div
  .name,
.marketplace-transaction-wrapper
  .rdt_Table
  .rdt_TableBody
  .rdt_TableRow
  .rdt_TableCell:nth-of-type(5)
  div {
  font-weight: 500;
  color: #101828;
}
.marketplace-transaction-wrapper
  .rdt_Table
  .rdt_TableBody
  .rdt_TableRow
  .rdt_TableCell[data-column-id='7'] {
  background-color: #fcfcfd;
  color: #039855;
}
.marketplace-transaction-wrapper
  .rdt_Table
  .rdt_TableBody
  .rdt_TableRow
  .rdt_TableCell[data-column-id='7']
  div {
  font-weight: 500;
}
.marketplace-transaction-wrapper
  .rdt_Table
  .rdt_TableBody
  .rdt_TableRow
  .rdt_TableCell[data-column-id='6'] {
  color: #ff8f50;
}
.marketplace-transaction-wrapper
  .rdt_Table
  .rdt_TableBody
  .rdt_TableRow
  .rdt_TableCell[data-column-id='6']
  div {
  font-weight: 500;
}
.marketplace-transaction-wrapper .rdt_Table button.fullfill-btn {
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
  border-radius: 100px;
  padding: 8px 14px;
  font-weight: 500;
  color: #344054;
}
.marketplace-transaction-wrapper .rdt_Table .refunded-text {
  text-align: center;
  display: block;
  font-weight: 500;
  color: #f04438 !important;
}
.marketplace-transaction-wrapper .rdt_Pagination {
  background-color: transparent;
  position: relative;
}
.marketplace-transaction-wrapper .rdt_Pagination #pagination-first-page,
.marketplace-transaction-wrapper .rdt_Pagination #pagination-last-page {
  display: none;
}
.marketplace-transaction-wrapper
  .rdt_Table
  .rdt_TableBody
  .rdt_TableRow
  .rdt_TableCell[data-column-id='4'],
.marketplace-transaction-wrapper
  .rdt_Table
  .rdt_TableBody
  .rdt_TableRow
  .rdt_TableCol[data-column-id='4'] {
  min-width: auto;
}
.marketplace-transaction-wrapper .rdt_Table .rdt_TableCol .eUeqdG {
  overflow: initial;
  white-space: initial;
}

.marketplace-stock-wrapper .marketplace-stock-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 28px;
}
.marketplace-stock-wrapper .marketplace-stock-header .header-button {
  display: flex;
  align-items: center;
  gap: 12px;
}
.marketplace-stock-wrapper .marketplace-stock-header .header-button button {
  background-color: #0f0742;
  color: #52ff9a;
  padding: 10px 16px 10px 44px !important;
  border: 1px solid #0f0742;
  position: relative;
}
.marketplace-stock-wrapper .marketplace-stock-header .header-button button:hover {
    background-color: #52ff9a;
    color: #0f0742;
    border: 1px solid #52ff9a;
}
.marketplace-stock-wrapper .marketplace-stock-header .header-button #publish-btn::before {
  content: '';
  position: absolute;
  left: 17px;
  top: 12px;
  width: 18px;
  height: 18px;
  background: url('../../../../../public/assets/images/stock-publish.svg') no-repeat center;
}
.marketplace-stock-wrapper .marketplace-stock-header .header-button #sync-btn::before {
  content: '';
  position: absolute;
  left: 17px;
  top: 12px;
  width: 20px;
  height: 17px;
  background: url('../../../../../public/assets/images/stock-sync.svg') no-repeat center;
}
.marketplace-stock-wrapper .marketplace-stock-header .header-button #publish-btn:hover::before,
.marketplace-stock-wrapper .marketplace-stock-header .header-button #sync-btn:hover::before{
    filter: brightness(0.3);
}
.marketplace-stock-wrapper .marketplace-stock-header .header-action .search-box .input-group {
  width: 320px;
  position: relative;
}
.marketplace-stock-wrapper
  .marketplace-stock-header
  .header-action
  .search-box
  .input-group::before {
  content: '';
  position: absolute;
  top: 14px;
  left: 16px;
  height: 17px;
  width: 17px;
  background: url('../../../../../public/assets/images/search.svg') no-repeat center;
  z-index: 4;
}
.marketplace-stock-wrapper .marketplace-stock-header .header-action .search-box .input-group input {
  padding: 10px 14px 10px 42px !important;
}
.marketplace-stock-wrapper .marketplace-stock-content {
  background: #ffffff;
  border: 1px solid #e4e7ec;
  box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
  border-radius: 18px;
  margin-bottom: 16px;
}
.marketplace-stock-wrapper .marketplace-stock-content .image-sec{
    position: relative;
}
.marketplace-stock-wrapper .marketplace-stock-content .image-sec .stock-banner-img {
  object-fit: cover;
  width: 100%;
  height: 125px;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
}
.marketplace-stock-wrapper .marketplace-stock-content .image-sec .edit-icon-wrapper{
    position: absolute;
    top: 15px;
    right: 14px;
    background-color: #ffffff;
    border-radius: 100%;
    height: 36px;
    width: 36px;
    text-align: center;
    line-height: 33px;
    cursor: pointer;
}
.marketplace-stock-wrapper .marketplace-stock-content .image-sec .edit-icon-wrapper img{
    width: 17px;
    height: 17px;
}
.marketplace-stock-wrapper .marketplace-stock-content .content-sec {
  padding: 13px 16px 16px 16px;
}
.marketplace-stock-wrapper .marketplace-stock-content .content-sec a{
  text-decoration: none;
}
.marketplace-stock-wrapper .marketplace-stock-content .content-sec .title {
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 8px;
  color: #0F0742;
}
.product-count{
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 8px;
  color: #667085;
}
.marketplace-stock-wrapper .marketplace-stock-content .content-sec label {
  display: none;
}
.marketplace-stock-wrapper .marketplace-stock-content .content-sec .input-group button {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}
.stock-upload-modal .file-upload-container{
  border: 1px solid #e4e7ec;
}
.stock-upload-modal .modal-image-area{
  border: 1px solid #3F3968;
  border-radius: 8px;
  margin-bottom: 20px;
}
.stock-upload-modal .modal-image-area .uploaded-image{
  object-fit: cover;
}
/*marketplace category design*/
.marketplace-stock-category-wrapper .top-header-container{
  background-color: #fff;
  border-radius: 18px;
  padding: 16px;
  margin-bottom: 24px;
}
.marketplace-stock-category-wrapper .top-header-container .stock-category{
  display: flex;
  align-items: center;
}
.marketplace-stock-category-wrapper .top-header-container .stock-category .image-area{
  width: 120px;
  margin-right: 16px;
}
.marketplace-stock-category-wrapper .top-header-container .stock-category .image-area .stock-category-img{
  height: 120px;
  width: 120px;
  object-fit: cover;
  border-radius: 8px;
}
.marketplace-stock-category-wrapper .top-header-container .stock-category .image-area{
  position: relative;
}
.marketplace-stock-category-wrapper .top-header-container .stock-category .image-area .edit-icon-wrapper{
  position: absolute;
  top: 4px;
  right: 4px;
  background-color: #fff;
  height: 36px;
  width: 36px;
  border-radius: 100%;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  cursor: pointer;
}
.marketplace-stock-category-wrapper .top-header-container .stock-category .image-area .edit-icon-wrapper img{
  height: 16px;
  vertical-align: bottom;
}
.marketplace-stock-category-wrapper .top-header-container .stock-category .image-area .edit-icon-wrapper > div{ 
  text-align: center;
  height: 100%;
}
.marketplace-stock-category-wrapper .top-header-container .stock-category .content-area .title{
  font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 8px;
    color: #0F0742;
}
.marketplace-stock-category-wrapper .top-header-container .stock-category .content-area .product-count{
  font-size: 18px;
    line-height: 30px;
    margin-bottom: 8px;
    color: #667085;
}
.marketplace-stock-category-wrapper .top-header-container .stock-category .content-area label{
  display: none;
}
.marketplace-stock-category-wrapper .product-container{
  background-color: #fff;
  border-radius: 18px;
  padding: 32px 16px;
}
.marketplace-stock-category-wrapper .product-container .header-action{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 32px 0;
}
.marketplace-stock-category-wrapper .product-container .header-action .search-box{
  position: relative;
}
.marketplace-stock-category-wrapper .product-container .header-action .search-box::before{
  content: "";
  position: absolute;
  left: 16px;
  top: 13px;
  background: url('../../../../../public/assets/images/search.svg') no-repeat center;
  height: 18px;
  width: 19px;
  z-index: 4;
}
.marketplace-stock-category-wrapper .product-container .header-action .search-box input{
  width: 320px;
  padding: 10px 14px 10px 42px!important;
}
.marketplace-stock-category-wrapper .product-container .header-action .search-box label{
  display: none;
}
.marketplace-stock-category-wrapper .product-container .header-action .filter-option{
  width: 177px;
}
.marketplace-stock-category-wrapper .product-container .product-list-contant{
  margin-bottom: 32px;
}
.marketplace-stock-category-wrapper .product-container .product-list{
  border-radius: 16px;
  border:1px solid #ddd;
  height: 100%;
}
.marketplace-stock-category-wrapper .product-container .product-list .image-wrapper img{
  height: 250px;
  object-fit: cover;
  width: 100%;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
.marketplace-stock-category-wrapper .product-container .product-list .product-detail{
  box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.05);
  padding: 16px 16px 20px 16px;
  min-height: 181px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.marketplace-stock-category-wrapper .product-container .product-list .product-detail .product-name{
  color: #0F0742;
  font-size: 18px;
  font-weight: 700;
  font-family: 'Quicksand';
  line-height: 30px;
  margin-bottom: 8px;
}
.marketplace-stock-category-wrapper .product-container .product-list .product-detail .amount-detail{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 22px;
}
.marketplace-stock-category-wrapper .product-container .product-list .product-detail .amount-detail .name{
  color: #98A2B3;
  font-size: 12px;
  font-weight: 500;
  width: 100px;
}
.marketplace-stock-category-wrapper .product-container .product-list .product-detail .amount-detail .amount{
  color: #0F0742;
  font-size: 14px;
  font-weight: 500;
}
.featured-product{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.featured-product .css-14el2xx-placeholder{
  padding: 4px 8px;
}
.marketplace-stock-category-wrapper .product-container .product-list .product-detail .featured-product .form-switch.toggle-switch .form-check-input:checked {
  background-color: #0F0742;
  border-color: #0F0742;
  background-image: url('../../../../../public/assets/images/switch-circle.svg')!important;
}
.marketplace-stock-category-wrapper .product-container .product-list .product-detail .featured-product .form-switch.toggle-switch .form-check-input {
  width: 44px!important;
  height: 24px!important;
  border-radius: 12px!important;
  margin-top: 0;
}
.marketplace-stock-category-wrapper .product-container .product-list .product-detail .featured-product .form-switch.toggle-switch .form-check-label{
  color: #667085;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-left: 14px;
}
.marketplace-stock-category-wrapper .product-container .product-list .product-detail .featured-product .select-area .app-select .css-1s2u09g-control{
  min-height: 34px!important;
  height: 34px!important;
}
.marketplace-stock-category-wrapper .product-container .product-list .product-detail .featured-product .select-area .app-select .css-319lph-ValueContainer,
.marketplace-stock-category-wrapper .product-container .product-list .product-detail .featured-product .select-area .app-select .css-tlfecz-indicatorContainer,
.marketplace-stock-category-wrapper .product-container .product-list .product-detail .featured-product .select-area .app-select .css-1gtu0rj-indicatorContainer{
  padding: 0 8px;
}
.marketplace-stock-category-wrapper .product-container .product-list .product-detail .featured-product .select-area .app-select .css-1gtu0rj-indicatorContainer{
  border-radius: 12px;
}
.marketplace-stock-category-wrapper .product-container .product-list .product-detail .featured-product .app-select > div{
  min-height: 34px;
}

@media screen and (min-width: 1025px) and (max-width: 1199px) {
  .marketplace-transaction-wrapper .marketplace-transaction-header {
    flex-direction: column;
    align-items: flex-start;
  }
  .marketplace-transaction-wrapper .marketplace-transaction-header .header-action .search-box {
    margin: 15px 0 0;
  }
  .marketplace-transaction-wrapper
    .marketplace-transaction-header
    .header-action
    .filter-btn-wrapper,
  .marketplace-transaction-wrapper
    .marketplace-transaction-header
    .header-action
    .download-btn-wrapper {
    margin-top: 15px;
  }
}

@media screen and (min-width:992px) and (max-width:1024px){
  .marketplace-stock-category-wrapper .product-container .product-list .product-detail .featured-product .form-switch.toggle-switch .form-check-label{
    font-size: 14px;
  }
}


@media screen and (min-width:992px) and (max-width:1400px){
  .marketplace-stock-category-wrapper .product-container .product-list .product-detail{
    padding: 10px;
  }
  .marketplace-stock-category-wrapper .product-container .product-list .product-detail .featured-product{
    flex-direction: column;
    align-items: flex-start;
  }
  .marketplace-stock-category-wrapper .product-container .product-list .product-detail .featured-product .select-area{
    margin-top: 10px;
  }
}

@media screen and (max-width: 1024px) {
  .marketplace-transaction-wrapper .marketplace-transaction-header {
    flex-direction: column;
    align-items: flex-start;
  }
  .marketplace-transaction-wrapper .marketplace-transaction-header .header-action {
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .marketplace-transaction-wrapper .marketplace-transaction-header .header-action .search-box {
    width: auto;
    margin: 15px 0 0;
  }
  .marketplace-transaction-wrapper
    .marketplace-transaction-header
    .header-action
    .search-box
    input {
    width: auto;
  }
  .marketplace-transaction-wrapper
    .marketplace-transaction-header
    .header-action
    .filter-btn-wrapper,
  .marketplace-transaction-wrapper
    .marketplace-transaction-header
    .header-action
    .download-btn-wrapper {
    margin-top: 15px;
  }
}

@media screen and (max-width: 991px) {
  .marketplace-transaction-wrapper .marketplace-transaction-header .header-action{
    width: 100%;
  }
  .marketplace-transaction-wrapper .marketplace-transaction-header .header-action .search-box {
    width: 60%;
    margin: 15px 15px 15px 0;
  }
  .marketplace-transaction-wrapper
    .marketplace-transaction-header
    .header-action
    .search-box
    input {
    width: auto;
  }
  .marketplace-transaction-wrapper
    .marketplace-transaction-header
    .header-action
    .filter-btn-wrapper {
    margin-left: 0;
  }
}
@media screen and (max-width:767px){
  .marketplace-stock-wrapper .marketplace-stock-header{
    flex-direction: column;
  }
  .marketplace-stock-wrapper .marketplace-stock-header .header-action .search-box .input-group{
    width: 100%;
  }
  .marketplace-transaction-wrapper .marketplace-transaction-header .header-action .search-box {
    width: 100%;
    margin: 15px 0;
  }
  .marketplace-transaction-wrapper
    .marketplace-transaction-header
    .header-action
    .filter-btn-wrapper,
  .marketplace-transaction-wrapper
    .marketplace-transaction-header
    .header-action
    .download-btn-wrapper {
    margin-top: 0;
  }
}
.frozen-transaction * {
  color: #D0D5DD !important;
}

.refund-row {
  background-color: #f0eaed !important;
}