.merchant-onboarding-container {
  height: 100vh;
}

/* .merchant-onboarding-container .main-content-area{
  height: 94.2vh;
} */

.merchant-onboarding-content {
  background-attachment: fixed;
}

.main-box{
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  flex-direction: column;
  background-image: url('/public/assets/images/onboarding_bg.svg');
  background-repeat: no-repeat;
  background-size: 1200px;
  height: auto;
}
.main-box .content-box-wrapper{
  width: 500px;
  margin: 0 auto;
}

.main-box.long-form-business,
.main-box.long-form-account{
  padding: 20px 0;
}

.onboarding-content-card{
  padding: 32px;
}

.onboarding-content-card h3{
  font-size: 30px;
  margin-bottom: 12px;
}

.onboarding-content-card p{
  color:#667085;
}

.main-box .dot {
  height: 10px;
  width: 10px;
  background-color: #CFCDD9;
  border-radius: 50%;
  display: inline-block;
  margin:80px 8px 5px 8px;
}

.main-box .dot.active {
  background-color: #0C0635;
}

/*Merchant admin*/

.merchant-admin-container{
  background-color: #FCFCFD;
  position: relative;
}

.merchant-admin-container .new-deal-card{
  cursor: pointer;
}

/*add new deal*/
.merchant-admin-container .merchant-admin-content{
  padding: 43px 112px 43px 35px;
}
.merchant-admin-container .add-new-deal-spacing{
  padding: 32px 68px;
}
.merchant-admin-container .add-new-deal-spacing .input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback){
  border-top-left-radius: 0!important;
  border-bottom-left-radius: 0!important;
}
.merchant-admin-container .add-new-deal-spacing .input-group .btn-outline-secondary,
.merchant-admin-container .add-new-deal-spacing .input-group .btn-outline-secondary:hover{
  border: 1px solid #ced4da;
  background-color: #F2F4F7!important;
  color: #6c757d;;
}
.merchant-admin-container .add-new-deal-spacing .input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3), 
.merchant-admin-container .add-new-deal-spacing .input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu){
  border-top-left-radius: 8px!important;
  border-bottom-left-radius: 8px!important;
}

.merchant-admin-container .wrapper-padding{
  padding: 24px 68px;
}
.merchant-admin-container .form-card-footer{
  padding: 24px 68px!important;
}
.merchant-admin-container .form-card-footer > div{
  margin-right: 8px;
}
.form-card-footer > div:last-child{
  margin-right: 0!important;
}

@media screen and (max-width:1440px){
  .main-box{
    background-size: 857px;
  }
  /* .merchant-onboarding-container .main-content-area {
    height: 92vh;
    min-height: 92vh;
  }  */
}

@media screen and (max-width:1366px){
  .main-box .dot {
    margin: 60px 8px 20px 8px;
  }
}

@media screen and (max-width:1299px){
  .merchant-admin-container .merchant-admin-content{
    padding: 35px;
  }
}

@media screen and (max-width:1199px){
  .merchant-admin-container .wrapper-padding{
    padding: 20px;
  }
  .merchant-list-wrapper .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell .merchant-detail{
    flex-direction: column;
  }
  .merchant-list-wrapper .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell .delete-icon{
    margin: 0 18px;
  }
  .survey-box .top-title-bar{
    padding: 10px;
  }
}

@media screen and (max-width:991px){
  .main-box .content-box-wrapper{
    width: 450px;
  }
  .merchant-admin-container .merchant-admin-content{
    padding: 20px;
  }
  .merchant-admin-container .form-card-footer{
    padding: 15px 20px 20px 20px!important;
  }
  .merchant-admin-container .wrapper-padding{
    padding: 15px;
  }
}

@media screen and (max-width:767px){
  .main-box{
    background-image: none;
  }
  .main-box .content-box-wrapper{
    width: 350px;
  }
  .merchant-onboarding-container .main-content-area {
    height: auto;
  }
}

@media screen and (max-width:320px){
  .main-box .content-box-wrapper{
    width: 320px;
  }
}