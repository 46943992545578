.text-input-wrapper {
}

.button-suffix-icon {
  border: 1px solid #ced4da !important;
  border-left: 0 !important;
  z-index: 0 !important;
}

.button-suffix-icon:hover {
  background-color: transparent !important;
}

.with-icon {
  border-right: 0 !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.button-prefix-icon {
  border: 1px solid #ced4da !important;
  border-right: 0 !important;
  z-index: 0 !important;
}

.button-prefix-icon:hover {
  background-color: transparent !important;
}

.with-icon-left {
  border-left: 0 !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.with-icon-left-text {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
