.markesurvey-wrapper .create-survey-btn button{
    width: auto!important;
    margin-bottom: 24px;
    padding: 9px 18px!important;
}
.markesurvey-wrapper .survey-box{
    background-color: #ffffff;
    border: 1px solid #E4E7EC;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 18px;
    margin-bottom: 16px;
}
.survey-box .top-title-bar{
    padding: 16px 24px 8px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.survey-box .top-title-bar .title{
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    color: #0F0742;
    font-family: 'Quicksand';
}
.survey-box .top-title-bar .action-btn span{
    cursor: pointer;
}
.markesurvey-wrapper .survey-box .survey-chart{
    background: #F3F3F6;
    padding: 30px 24px 16px;
    position: relative;
}
.markesurvey-wrapper .survey-box .survey-chart .total-responses{
    position: absolute;
    font-size: 12px;
    font-weight: 500;
    top: 5px;
}
.markesurvey-wrapper .survey-box .survey-chart .survey-chart-wrapper{
    height: 120px;
    position: relative;
}
.markesurvey-wrapper .survey-box .survey-content{
    padding: 8px 24px 8px 24px;
}
.markesurvey-wrapper .survey-box .survey-content .survey-detail{
    display: flex;
    align-items: center;
    gap:10px;  
    margin-bottom: 8px;
}
.markesurvey-wrapper .survey-box .survey-content .survey-detail .left-title{
    width: 85px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #98A2B3;
}
.markesurvey-wrapper .survey-box .survey-content .survey-detail .right-value{
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #0F0742;
    text-transform: capitalize;
}
.markesurvey-wrapper .survey-box .survey-chart .survey-chart-wrapper .center-value{
    position: absolute;
    top: 40%;
    left: 32%;
    font-weight: 600;
}
/*create survey*/
.merchantsurvey-create-edit-wrapper .combine-option{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap:28px
}
.merchantsurvey-create-edit-wrapper .textbox-option-area{
    width: 100%;
}
.merchantsurvey-create-edit-wrapper .add-logo-part{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
}
.merchantsurvey-create-edit-wrapper .add-logo-part .add-logo-div{
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-right: 20px;
    margin-top: 40px;
    cursor: pointer;
    width: 111px;
}
.merchantsurvey-create-edit-wrapper .add-logo-part .add-logo-div img{
    margin-right: 12px;
    margin-top: -2px;
    transform: rotate(45deg);
}
.merchantsurvey-create-edit-wrapper .add-logo-part a.close-btn{
    margin-top: 40px;
}
.merchantsurvey-create-edit-wrapper .add-another-option{
    margin-top: 24px;
}
.merchantsurvey-create-edit-wrapper .add-another-option span{
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #0F0742;
    cursor: pointer;
}
.merchantsurvey-create-edit-wrapper .add-another-option span img{
    margin-right: 12px;
    transform: rotate(45deg);
    margin-top: -2px;
}
.merchantsurvey-create-edit-wrapper .add-logo-part .display-logo-div{
    height: 44px;
    width: 44px;
    border: 1px solid #D0D5DD;
    filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 44px;
    margin-right: 30px;
}
.delete-survey-modal .modal-footer {
    display: flex!important;
    justify-content: space-between!important;
}
.delete-survey-modal .modal-footer div {
    width: 48%;
}
.merchantsurvey-create-edit-wrapper .top-box-bar{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.merchantsurvey-create-edit-wrapper .top-box-bar .right-download-btn button{
    background-color: #ffffff;
    color: #344054;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 100px;
    padding: 10px 16px 10px 44px!important;
    position: relative;
    font-size: 14px;
}
.merchantsurvey-create-edit-wrapper .top-box-bar .right-download-btn button:hover{
    background-color: #f2f2f2;
}
.merchantsurvey-create-edit-wrapper .top-box-bar .right-download-btn button::before{
    content: "";
    position: absolute;
    background: url('/public/assets/images/download.svg') no-repeat center;
    left: 18px;
    top: 13px;
    width: 17px;
    height: 17px;
}
.merchantsurvey-create-edit-wrapper .result-wrapper{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 24px;
}
.merchantsurvey-create-edit-wrapper .result-wrapper .result-box{
    width: 176px;
    height: 168px;
    background: #0F0742;
    border: 1px solid #E4E7EC;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 18px;
    margin-right: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.merchantsurvey-create-edit-wrapper .result-wrapper .result-box .text{
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
}
.merchantsurvey-create-edit-wrapper .result-wrapper .result-box .result-answer{
    font-size: 48px;
    line-height: 60px;
    letter-spacing: 0.05em;
    color: #52FF9A;
    font-weight: 700;
}
.merchantsurvey-create-edit-wrapper .result-wrapper .result-progress-bar{
    width: 85%;
}
.merchantsurvey-create-edit-wrapper .result-wrapper .result-progress-bar .progress-bar-wrapper{
    width: 100%;
    gap:12px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.merchantsurvey-create-edit-wrapper .result-wrapper .result-progress-bar span{
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #344054;
    cursor: pointer;
}
.merchantsurvey-create-edit-wrapper .result-wrapper .result-progress-bar .progress{
    height: 8px;
    border-radius: 4px;
    width: 100%;
    background: #E7E6EC;
}
.merchantsurvey-create-edit-wrapper .result-wrapper .result-progress-bar .progress .progress-bar{
    background: #0F0742;
    border-radius: 4px
}
.merchantsurvey-create-edit-wrapper .result-wrapper .result-progress-bar .progress-bar-items{
    /* margin-top: 15px; */
    margin-bottom: 15px;
}
.markesurvey-wrapper .survey-box.in-active-box{
}
.markesurvey-wrapper .survey-box.in-active-box .top-title-bar .title,
.markesurvey-wrapper .survey-box.in-active-box .survey-content .survey-detail .right-value{
    color: #D0D5DD;
}
.markesurvey-wrapper .survey-box.in-active-box .in-active-chart{
    height: 120px;
    width: 120px;
    border: 16px solid #E4E7EC;
    border-radius: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 12px;
    font-weight: 400;
}

@media screen and (max-width:1600px){
    .markesurvey-wrapper .survey-box .survey-chart .survey-chart-wrapper .center-value{
        left: 27%;
    }
}

@media screen and (max-width:1440px){
    .merchantsurvey-create-edit-wrapper .textbox-option-area{
        width: 80.5%;
    }
    .markesurvey-wrapper .survey-box .survey-chart .survey-chart-wrapper .center-value{
        left: 24%;
    }
}

@media screen and (max-width:991px){
    .merchantsurvey-create-edit-wrapper .top-box-bar .top-heading-title{
        width: 70%;
    }
    .markesurvey-wrapper .survey-box .survey-chart .survey-chart-wrapper .center-value{
        left: 26%;
    }
}

@media screen and (max-width:767px){
    .merchantsurvey-create-edit-wrapper .combine-option{
        flex-direction: column;
        gap:0
    }
    .merchantsurvey-create-edit-wrapper .textbox-option-area {
        width: 100%;
    }
    .merchantsurvey-create-edit-wrapper .add-logo-part{
        justify-content: flex-start;
        width: 100%;
        margin-top: 20px;
    }
    .merchantsurvey-create-edit-wrapper .add-logo-part .add-logo-div,
    .merchantsurvey-create-edit-wrapper .add-logo-part a.close-btn{
        margin-top: 0;
    }
    .merchantsurvey-create-edit-wrapper .top-box-bar .top-heading-title{
        width: 100%;
    }
    .merchantsurvey-create-edit-wrapper .top-box-bar{
        flex-direction: column;
    }
    .merchantsurvey-create-edit-wrapper .result-wrapper{
        flex-direction: column;
    }
    .merchantsurvey-create-edit-wrapper .result-wrapper .result-progress-bar {
        width: 100%;
    }
    .markesurvey-wrapper .survey-box .survey-chart .survey-chart-wrapper .center-value{
        left: 24%;
    }
}
