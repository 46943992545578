.user-list-wrapper{
    border: 1px solid #E4E7EC;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    border-radius: 8px;
    background-color: #fff;
}
.user-list-wrapper .user-list-header{
    padding: 20px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom:1px solid #E4E7EC;
}
.user-list-wrapper .user-list-header .header-title{
    display: flex;
    align-items: center;
}
.user-list-wrapper .user-list-header .header-title .image img{
    margin-right: 16px;
}
.user-list-wrapper .user-list-header .header-title .text{
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
}
.user-list-wrapper .user-list-header .header-title .text > div{
    text-align: center;
}
.user-list-wrapper .user-list-header .header-title .text .user-count{
    font-size: 12px;
    font-weight: 500;
    background-color: #E7E6EC;
    padding: 2px 8px;
    border-radius: 16px;
    line-height: 18px;
}
.user-list-wrapper .user-list-header .header-action{
    display: flex;
    align-items: center;
    justify-content: center;
}
.user-list-wrapper .user-list-header .header-action .search-box{
    position: relative;
}
.user-list-wrapper .user-list-header .header-action .search-box div label{
    display: none;
}
.user-list-wrapper .user-list-header .header-action .search-box input{
    width: 320px;
    padding: 10px 14px 10px 42px!important;
}
.user-list-wrapper .user-list-header .header-action .search-box:before{
    position: absolute;
    content: "";
    background: url('/public/assets/images/search.svg') no-repeat center;
    left: 16px;
    top: 14px;
    width: 17px;
    height: 17px;
    z-index: 4;
}
.user-list-wrapper .user-list-header .header-action .filter-btn-wrapper{
    margin-right: 8px;
    margin-left: 24px;
}
.user-list-wrapper .user-list-header .header-action .filter-btn-wrapper > div,
.user-list-wrapper .user-list-header .header-action .download-btn-wrapper > div{
    margin-top: 0!important;
}
.user-list-wrapper .user-list-header .header-action .filter-btn-wrapper button,
.user-list-wrapper .user-list-header .header-action .download-btn-wrapper button{
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    padding: 10px 16px 10px 44px!important;
    color: #344054;
    font-size: 14px;
    position: relative;
}
.user-list-wrapper .user-list-header .header-action .download-btn-wrapper button{
    padding: 10px 35px 10px 44px!important;
}
.user-list-wrapper .user-list-header .header-action .download-btn-wrapper{
    position: relative;
}
.user-list-wrapper .user-list-header .header-action .download-btn-wrapper::after{
    position: absolute;
    content: "";
    right: 18px;
    top: 20px;
    width: 7px;
    height: 4px;
    background: url('/public/assets/images/download-arrow.svg') no-repeat center;
}
.user-list-wrapper .user-list-header .header-action .filter-btn-wrapper button::before{
    position: absolute;
    content: "";
    background: url('/public/assets/images/filter.svg') no-repeat center;
    left: 18px;
    top: 15px;
    width: 15px;
    height: 12px;
}
.user-list-wrapper .user-list-header .header-action .download-btn-wrapper button::before{
    position: absolute;
    content: "";
    background: url('/public/assets/images/download.svg') no-repeat center;
    left: 17px;
    top: 12px;
    width: 17px;
    height: 17px;
}
.user-list-wrapper .user-list-header .header-action .filter-btn-wrapper button:hover,
.user-list-wrapper .user-list-header .header-action .download-btn-wrapper button:hover{
    color: #344054;
    background: #F9FAFB; 
}
.user-list-wrapper .user-list-header .header-action .download-btn-wrapper .download-dropdown-list{
    position: absolute;
    z-index: 2;
    background-color: #fff;
    width: 148px;
    box-shadow: 0px 12px 16px -4px rgb(16 24 40 / 10%), 0px 4px 6px -2px rgb(16 24 40 / 5%);
    border-radius: 8px;
}
.user-list-wrapper .user-list-header .header-action .download-btn-wrapper .download-dropdown-list ul{
    margin-bottom: 0;
    padding: 0;
}
.user-list-wrapper .user-list-header .header-action .download-btn-wrapper .download-dropdown-list ul li{
    list-style: none;
    padding: 12px 17px 12px 42px;
    position: relative;
}
.user-list-wrapper .user-list-header .header-action .download-btn-wrapper .download-dropdown-list ul li.csv-link::before{
    position: absolute;
    content: "";
    background: url('/public/assets/images/csv.svg') no-repeat center;
    top: 16px;
    left: 17px;
    width: 17px;
    height: 19px;
}
.user-list-wrapper .user-list-header .header-action .download-btn-wrapper .download-dropdown-list ul li.pdf-link::before{
    position: absolute;
    content: "";
    background: url('/public/assets/images/pdf.svg') no-repeat center;
    top: 16px;
    left: 17px;
    width: 17px;
    height: 19px;
}
.user-list-wrapper .user-list-header .header-action .download-btn-wrapper .download-dropdown-list ul li:hover{
    background: #F9FAFB;
    border-radius: 8px; 
}
.user-list-wrapper .user-list-header .header-action .download-btn-wrapper .download-dropdown-list ul li a{
    color: #0F0742;
    font-weight: 300;
    text-decoration: none;
    display: block;
}
.user-list-wrapper .rdt_Table .rdt_TableHead .rdt_TableHeadRow{
    background: #F9FAFB; 
}
.user-list-wrapper .rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol .rdt_TableCol_Sortable{
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #667085;
}
.user-list-wrapper .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell{
    padding: 16px;
}
.user-list-wrapper .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell div{
    font-size: 14px;
    font-weight: 300;
    color: #667085;
}
.user-list-wrapper .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell:nth-of-type(1) div .name{
    font-weight: 500;
    color: #101828;
}
.user-list-wrapper .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell:nth-of-type(4) div .time-zone{
    color: #101828;
    font-weight: 400;
}
.user-list-wrapper .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell .delete-icon{
    margin: 0 28px;
}
.user-list-wrapper .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell .active-status{
    padding: 2px 8px 2px 20px;
    background-color: #ECFDF3;
    font-size: 12px;
    font-weight: 500;
    color: #027A48;
    border-radius: 16px;
    position: relative;
}
.user-list-wrapper .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell .active-status::before {
    content: "";
    position: absolute;
    left: 7px;
    top: 8px;
    width: 6px;
    height: 6px;
    border-radius: 100%;
    /* z-index: 999999; */
    background-color: #027A48;
}
.user-list-wrapper .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell .frozen-status{
    padding: 2px 8px 2px 20px;
    background: rgba(0, 194, 255, 0.1);
    font-size: 12px;
    font-weight: 500;
    color: #00C2FF;
    border-radius: 16px;
    position: relative;
}
.user-list-wrapper .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell .frozen-status::before {
    content: "";
    position: absolute;
    left: 7px;
    top: 8px;
    width: 6px;
    height: 6px;
    border-radius: 100%;
    /* z-index: 999999; */
    background-color: #00C2FF;
}
.user-list-wrapper .rdt_Pagination{
    background-color: transparent;
    position: relative;
}
.user-list-wrapper .rdt_Pagination #pagination-first-page,
.user-list-wrapper .rdt_Pagination #pagination-last-page{
    display:none
}
.filter-modal.user-list-filter-modal .rdrDefinedRangesWrapper{
    display: none;
}
.user-list-filter-modal .user-filter{
    margin-top: 15px;
    border-top: 1px solid #e9e9e9;
    padding-top: 10px;
}
.user-list-filter-modal .user-filter .common-title{
    margin-bottom: 8px;
}
.user-list-filter-modal .user-filter-list .form-label{
    display: none;
}
.user-list-filter-modal .user-filter-list .input-group{
    margin-bottom: 8px;
}
.user-list-filter-modal .user-filter-list .input-group input,
.user-list-filter-modal .user-filter-list .input-group select,
.user-list-filter-modal .user-filter-list .input-group input::placeholder,
.user-list-filter-modal .user-filter-list .input-group select::placeholder{
    color: #101828;
}
.user-list-filter-modal .user-filter-list .css-14el2xx-placeholder{
    padding: 7px 5px!important;
}
.user-list-filter-modal .modal-footer{
    margin-top: 32px;
}
.delete-user-list-modal .modal-footer {
    display: flex!important;
    justify-content: space-between!important;
}
.delete-user-list-modal .modal-footer div {
    width: 48%;
}
.user-list-edit-wrapper .main-content-wrapper .top-profile-info{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
}
.user-list-edit-wrapper .main-content-wrapper .top-profile-info .user-score{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
}
.user-list-edit-wrapper .main-content-wrapper .top-profile-info .user-score .blue-box{
    background: #0F0742;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    border-radius: 14px;
    width: 163px;
    min-height: 128px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 5px;
}
.user-list-edit-wrapper .main-content-wrapper .top-profile-info .user-score .blue-box p{
    font-size: 12px;
    color: rgba(255,255,255,0.8);
    line-height: 20px;
    font-weight: 500;
    margin: 4px 0 8px 0;
}
.user-list-edit-wrapper .main-content-wrapper .top-profile-info .user-score .blue-box .count{
    background-color: #ffffff;
    padding: 10px 14px;
    border-radius: 8px;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
    width: 123px;
    min-height: 44px;
    color: #667085;
}
.user-list-edit-wrapper .main-content-wrapper .top-profile-info .user-score .blue-box .count span{
    font-weight: 700;
    color: #101821;
    margin-left: 8px;
}
.user-list-wrapper .rdt_TableBody .frozen-user .rdt_TableCell:nth-of-type(1){
    opacity: 0.4;
}
.user-list-wrapper .action-btn-wrapper{
    position: relative;
}
.user-list-wrapper .action-btn-wrapper .pause-icon .tooltip-custom{
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition: all ease-in 0.2s;
    padding: 8px 12px;
    background: #101828;
    border-radius: 8px;
    left: -41px;
    top: -40px;
    color: #ffffff;
    text-decoration: none;
}
.user-list-wrapper .action-btn-wrapper .pause-icon .tooltip-custom::after{
    content: "";
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid #101828;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -7px;
    margin: 0 auto;
    width: 7px;
}
.user-list-wrapper .pause-icon:hover .tooltip-custom{
    opacity: 1;
    visibility: visible;
    z-index: 1;
}
.user-list-edit-wrapper .top-profile-info .user-profile-pic{
    position: relative;
}
.user-list-edit-wrapper .top-profile-info .user-profile-pic .file-input-picker-rounded img {
    position: absolute;
    width: 40px;
    height: 40px;
    bottom: 0;
    right: 11px;
    background-color: #ffffff;
    border-radius: 100%;
    object-fit: none;
    padding: 5px;
    box-shadow: 0 4px 10px 0 rgb(0 0 0 / 30%);
}

.file-input-picker-rounded.admin img.edit {
    bottom: 60px;
    right: unset;
    left: 32px;
    background-color: unset;
    backdrop-filter: blur(10px);
    text-shadow: 2px 2px black;
}

.file-input-picker-rounded.admin img.delete {
    bottom: 60px;
    right: unset;
    left: 72px;
    background-color: unset;
    backdrop-filter: blur(10px);
    text-shadow: 2px 2px black;
}

.file-input-picker-rounded.admin span {
    text-shadow: 2px 2px black;
    font-weight: 400;
    margin-top: 28px;
}

@media screen and (min-width:1025px) and (max-width:1199px){
    .user-list-wrapper .user-list-header{
        flex-direction: column;
        align-items: flex-start;
    }
    .user-list-wrapper .user-list-header .header-action .search-box{
        margin: 15px 0 0;
    }
    .user-list-wrapper .user-list-header .header-action .filter-btn-wrapper,
    .user-list-wrapper .user-list-header .header-action .download-btn-wrapper{
        margin-top: 15px;
    }
}

@media screen and (max-width:1024px){
    .user-list-wrapper .user-list-header{
        flex-direction: column;
        align-items: flex-start;
    }
    .user-list-wrapper .user-list-header .header-action{
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .user-list-wrapper .user-list-header .header-action .search-box{
        width: auto;
        margin: 15px 0 0;
    }
    .user-list-wrapper .user-list-header .header-action .search-box input{
        width: auto;
    }
    .user-list-wrapper .user-list-header .header-action .filter-btn-wrapper,
    .user-list-wrapper .user-list-header .header-action .download-btn-wrapper{
        margin-top: 15px;
    }
}

@media screen and (max-width:991px){
    .user-list-wrapper .user-list-header .header-action{
        width: 100%;
    }
    .user-list-wrapper .user-list-header .header-action .search-box{
        width: 60%;
        margin: 15px 15px 15px 0;
    }
    .user-list-wrapper .user-list-header .header-action .search-box input{
        width: auto;
    }
    .user-list-wrapper .user-list-header .header-action .filter-btn-wrapper{
        margin-left: 0;
    }
    /* .user-list-wrapper .user-list-header .header-action .filter-btn-wrapper,
    .user-list-wrapper .user-list-header .header-action .download-btn-wrapper{
        margin-top: 0;
    } */
    .user-list-wrapper .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell .delete-icon{
        margin: 0 12px;
    }
}
@media screen and (max-width:767px){
    .user-list-wrapper .user-list-header .header-action .search-box{
        width: 100%;
        margin: 15px 0;
    }
    .user-list-wrapper .user-list-header .header-action .filter-btn-wrapper,
    .user-list-wrapper .user-list-header .header-action .download-btn-wrapper{
        margin-top: 0;
    }
}

.user-image {
    height: 30px;
    width: 30px;
    object-fit: contain;
    border-radius: 15px;
    margin-right: 12px;
}