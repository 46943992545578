.feedback-modal .modal-header {
  border-bottom: 0;
}
.feedback-modal .modal-header .btn-close{
  position: absolute;
  right: 15px;
  top: 15px;
  display: none;
}
.feedback-modal .modal-footer {
  border-top: 0;
}

.feedback-modal .modal-header img{
  margin-bottom: 18px;
}

.feedback-modal .modal-body h4{
  font-size: 18px!important;
  line-height: 28px!important;
  margin-bottom: 7px;
}
.feedback-modal .modal-body p{
  font-size: 14px;
  color: #667085!important;
  line-height: 20px;
  margin-bottom: 0;
  font-weight: 400;
}
.feedback-modal .modal-footer button{
  margin-top: 32px;
}
.feedback-modal .modal-footer>div{
  margin: 0!important;
}
