.main-box .content-box-wrapper.terms-card{
  width: 700px;
}
.terms-container {
  background-color: #f9fafb;
  padding: 32px 16px 28px;
  max-height: 410px;
  overflow-y: auto;
  border-radius: 14px;
}
.terms-container h5{
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}
.terms-container p{
  font-size: 14px;
  color: #000000;
}
.w-custom{
  width: 56.8%;
}
@media screen and (max-width:991px){
  .main-box .content-box-wrapper.terms-card{
    width: 450px;
  }
}

@media screen and (max-width:767px){
  .main-box .content-box-wrapper.terms-card{
    width: 350px;
  }
  .w-custom{
    width: 100%;
  }
}

@media screen and (max-width:320px){
  .main-box .content-box-wrapper.terms-card{
    width: 320px;
  }
}
