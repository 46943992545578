.off-canvas{
    width: 392px!important;
    border-left: 0!important;
    background: #0F0742!important;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    color:#ffffff!important;
    padding: 90px 32px 32px 32px;
}
.off-canvas .offcanvas-header{
    align-items: flex-start;
    margin-bottom: 38px;
}
.off-canvas .offcanvas-header .title{
    font-family: 'Quicksand';
    font-weight: 500;
    font-size: 30px;
    line-height: 38px;
}
.off-canvas .offcanvas-header .sub-title{
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}
.off-canvas .btn-close{
    opacity: 1!important;
    filter: invert(1);
}
.off-canvas .offcanvas-header,
.off-canvas .offcanvas-body{
    padding: 0;
}
.off-canvas .offcanvas-body .list-detail {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    max-width: 80%;
    margin-bottom: 20px;
    padding-bottom: 20px;
}
.off-canvas .offcanvas-body .list-detail .other-name{
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
}
.off-canvas .offcanvas-body .list-detail .user-info{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap:8px;
    padding-bottom: 12px;
}
.off-canvas .offcanvas-body .list-detail .user-info .pic{
    height: 20px;
    width: 20px;
    border-radius: 100%;
    display: flex;
}
.off-canvas .offcanvas-body .list-detail .user-info .name{
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #98A2B3;
}
.off-canvas.notification-off-canvas .offcanvas-body .list-detail{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.off-canvas.notification-off-canvas .offcanvas-body .list-detail .user-info{
    margin-right: 5px;
}
.off-canvas.notification-off-canvas .offcanvas-body .list-detail .other-name{
    order: 2;
    width: 62%;
    margin-right: 12px;
}
.off-canvas .offcanvas-body .list-detail .time{
    font-size: 12px;
    line-height: 18px;
    color: #98A2B3;
    order:3;
    width: 19.3%;
}
.off-canvas.notification-off-canvas .offcanvas-body .list-detail .user-info .pic{
    height: auto;
    width: auto;
}
.off-canvas .offcanvas-body .list-detail .new-notification{
    height: 8px;
    width: 8px;
    background-color: #52FF9A;
    border-radius: 100%;
    order: 4;
}
