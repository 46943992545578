.new-deal-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 16px;
  height: 304px;
  background: #ffffff;
  border: 1px solid #e4e7ec !important;
  box-shadow: 0px 1px 2px rgb(16 24 40 / 5%) !important;
  border-radius: 18px !important;
}

.new-deal-card-text {
  color: #98a2b3;
  font-size: 24px;
}

.deal-card {
  padding: 0px;
  min-height: 304px;
  background: #ffffff;
  border: 1px solid #e4e7ec !important;
  box-shadow: 0px 1px 2px rgb(16 24 40 / 5%) !important;
  border-radius: 18px !important;
  margin-bottom: 15px;
}
.deal-card .deal-image-wrapper{
  height: 125px;
}
.deal-card .deal-image-wrapper .deal-image{
  height: 125px;
  object-fit: cover;
}
.deal-card .card-body{
  padding-top: 30px;
}
.deal-card .card-body.orange-line{
  border-top: 5px solid #FF8F50;
}
.deal-card .card-body.green-line{
  border-top: 5px solid #52FF9A;;
}
.deal-card .title {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  color: #0f0742;
}

.deal-card .data-label {
  font-size: 12px;
  line-height: 18px;
  color: #98a2b3;
}

.deal-card .data-value {
  font-size: 14px;
  line-height: 20px;
  color: #0f0742;
}

.deal-card .deal-image {
  width: 100%;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  height: 125px;
  object-fit: cover;
}

.deal-card .type-image {
  position: absolute;
  left: 14px;
  bottom: -20px;
}

.deal-card .action-btn {
  height: 14px;
  width: 14px;
  cursor: pointer;
  margin-left: 14px;
}
.deal-card .featured-product{
  margin-top: 18px;
}
.deal-card .featured-product label{
  margin-left: 14px;
  font-weight: 500;
  color: #667085;
}
.deal-card.not-completed .deal-image{
  filter: grayscale(1);
  opacity: 0.6;
}
.deal-card .card-content{
  display: flex;
  flex-wrap: initial;
}
.deal-card.not-completed .card-content{
  opacity: 0.6;
  pointer-events: none;
}
.deal-card .card-content .left{
  width: 85px;
}
.deal-card .deal-name-top{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.deal-card.not-completed .card-content .data-value{
  color: #98a2b3!important;
}
.deal-card.not-completed .featured-product{
  pointer-events: none;
  opacity: 0.4;
}
/* .deal-card .deal-badge{
  display: none
} */
.deal-card .deal-badge.inactive-badge .inactive-view{
  display: inline-block;
  background-color: #f04438;
  border-radius: 50px;
  padding: 2px 7px;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  position: absolute;
  top: 16px;
  right: 16px;
}
.deal-card .deal-badge.inactive-badge .active-view{
  display: none;
}
.deal-card .deal-badge.active-badge .active-view{
  display: inline-block;
  background-color: #52FF9A;
  border-radius: 50px;
  padding: 2px 7px;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  position: absolute;
  top: 16px;
  right: 16px;
}
.deal-card .deal-badge.active-badge .inactive-view{
  display: none;
}
.merchant-admin-content .deal-top-bar{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.merchant-admin-content .deal-top-bar > div,
.merchant-admin-content .deal-top-bar .justify-content-end > div{
  margin-top: 0px!important;
}
.merchant-admin-content .deal-top-bar .form-select{
  width: 177px;
}
.delete-deal-modal .modal-footer{
  display: flex!important;
  justify-content: space-between!important;
}
.delete-deal-modal .modal-footer div{
  width: 48%;
}
.deal-wrapper .input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback){
  border-top-left-radius: 0!important;
  border-bottom-left-radius: 0!important;
}
.deal-wrapper .input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3), 
.deal-wrapper .input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu){
  border-top-left-radius: 8px!important;
  border-bottom-left-radius: 8px!important;
  border: 1px solid #ced4da;
}
.deal-wrapper .input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3){
  padding-right: 36px;
}
.deal-wrapper .input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3),
.deal-wrapper .input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3):hover{
  background: #F2F4F7;
  color: #6c757d;
  border-right: 0;
}
.deal-wrapper .dropdown-toggle::after{
  border: 0;
  position: absolute;
  right: 15px;
  top: 15px;
  background: url('/public/assets/images/down.svg') no-repeat center;
  width: 15px;
  height: 15px;
}
.deal-top-bar .css-b62m3t-container{
  min-width: 177px;
}
.deal-top-bar .css-tj5bde-Svg{
  fill: #667085;
}
.deal-top-bar .css-14el2xx-placeholder{
  color: #101828;
}
.deal-alert-box .alert{
  border: 1px solid #FF8F50;
  border-radius: 14px;
  padding: 16px;
}
.deal-alert-box .alert.app-alerts span.message{
  margin-left: 10px;
}
.deal-alert-box .alert{
  margin-bottom: 25px;
}
.deal-alert-box .app-alerts .right-side img{
  margin-left: 12px;
}
.deal-status-switch #status-switch{
  width: 44px;
  height: 24px;
  float: none;
  margin-right: 12px;
  background-color: #F2F4F7;
  border: 0;
  opacity: 1;
  background-image: url('/public/assets/images/deal-switch-disable.svg');
  background-position: left center;
  background-repeat: no-repeat;
}
.deal-status-switch #status-switch.form-check-input:checked {
  background-color: #0F0742;
  border-color: #0F0742;
  background-image: url('/public/assets/images/switch-circle.svg')!important;
  background-position: right center;
}
.deal-status-switch .form-check-label{
  font-size: 16px;
  line-height: 24px;
  color: #98a2b3;
  font-weight: 500;
  margin-top: 8px;
  opacity: 1!important;
  vertical-align: text-top;
}
.top-deal-section{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.top-deal-section .right-deal-info{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -5px;
}
.top-deal-section .right-deal-info img{
  margin-left: 9px;
  margin-top: 2px;
  cursor: pointer;
}
.app-tooltip{
  margin-bottom: 16px!important;
}
.app-tooltip .tooltip-inner{
  border-radius: 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}
.deal-wrapper .list-group .image-section-line.list-group-item{
  border:1px solid #3F3968!important;
  border-radius: 8px;
  padding: 8px 20px 8px 8px;
  margin-top: 16px;
}

@media screen and (max-width:767px){
  .alert-information .app-alerts > div{
    flex-direction: column;
  }
  .deal-card .card-body .card-content.row{
    flex-wrap: initial;
  }
  .deal-card .card-body .card-content.row .col-md-3{
    width: 30%;
  }
  .deal-card .card-body .card-content.row .col-md-9{
    width: 70%;
  }
}
