.merchant-account-status .title{
    font-size: 18px;
    line-height: 28px;
    color: #fff;
}
.merchant-account-status .sub-title{
    font-size: 14px;
    line-height: 20px;
}
.merchant-account-status .application-status-top{
    background-color: #ffffff!important;
    font-size: 14px!important;
    padding: 4px 12px 4px 24px!important;
    border: 1px solid #fff!important;
    position: relative;
}
.merchant-account-status .application-status-top:before{
    content: "";
    position: absolute;
    left: 12px;
    top: 12px;
    width: 6px;
    height: 6px;
    border-radius: 100%;
}
.merchant-account-status .application-status-bottom{
    padding: 8px 14px!important;
    font-size: 14px!important;
    font-weight: 500;
}
.merchant-account-status .cheque-image{
    margin-left: -10px;
}
.merchant-account-status[status="completed"] .title{
    color:rgb(15, 7, 66)
}
.merchant-account-status[status="completed"] .application-status-bottom{
    color:#52ff9a!important
}
.merchant-account-status[status="completed"] .application-status-bottom:hover{
    border-color:rgb(15, 7, 66);
}
.merchant-application .modal-footer{
    display: flex!important;
    justify-content: space-between!important;
}
.merchant-application .modal-footer div{
    width: 48.5%;
}
.status-info{
    opacity: 0.5;
}
.status-info.current, .status-info.active{
    opacity: 1;
}
.status-info p{
    font-size: 14px;
    line-height: 20px;
}
.status-info span{
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
}

.merchant-form .form-switch.toggle-switch .form-check-input{
    width: 44px!important;
    height: 24px!important;
    border-radius: 12px!important;
}
.merchant-form .form-switch.toggle-switch .form-check-input:checked{
    background-color: #0F0742;
    border-color: #0F0742;
    background-image:url("/public/assets/images/switch-circle.svg")!important;
}

.merchant-form .form-switch label {
    margin-left: 12px;
    margin-top: 3px;
}

.mr-2 {
    margin-right: 4px;
}
@media screen and (max-width:767px){
    .cheque-image{
        width: 100%;
    }
}