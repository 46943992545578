body {
  margin: 0;
  font-family: 'Noto Sans';
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0f0742;
  font-weight: 300;
}

code {
}

.form-title {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  color: #0f0742;
}

.form-subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #98a2b3;
}

.progress-wrapper {
  background: #0f0742;
  border-radius: 14px;
}

.bg-primary {
  background: #0f0742 !important;
}

.btn-cancel,
.btn-cancel:hover {
  background-color: #ffffff !important;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 25px;
  border-color: #d0d5dd !important;
  color: #344054 !important;
}

.form-card {
  border-radius: 25px !important;
}

.form-card .card-body {
  border-top-left-radius: 25px !important;
  border-top-right-radius: 25px !important;
}

.form-card .card-footer {
  border-bottom-left-radius: 25px !important;
  border-bottom-right-radius: 25px !important;
}

.side-menu .list-group-item,
.side-menu .list-group-item a {
  background: transparent;
  border-color: transparent;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #f2f4f7 !important;
  text-decoration: none;
  display: block;
}
.side-menu ul.list-group li a img {
  vertical-align: bottom;
}

.side-menu .child-menu {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 14px;
}

.file-input-picker-rounded {
  display: flex;
  height: 150px;
  width: 150px;
  justify-content: center;
  align-items: center;
  background: url('../public/assets/images/dashboard-top-dummy.png') center no-repeat;
  border-radius: 75px;
  flex-direction: column;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border: 1px solid #ccc;
}

.file-input-picker-rounded input {
  visibility: hidden;
  height: 0px;
}

.line-status {
  height: 4px;
  width: 100%;
  background: #e4e7ec;
  margin-bottom: 10px;
}

.line-status.active {
  background: #52ff9a;
}

.line-status.current {
  background: #0f0742;
}

.color-error {
  color: #f04438 !important;
}

.error-bg {
  background-color: #d92d20 !important;
  background: #d92d20 !important;
}

.app-alerts.alert-warning {
  background-color: #fffcf5 !important;
  background: #fffcf5 !important;
}

.app-alerts span.message {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #ff8f50;
}

.app-alerts .badge.bg-warning {
  background-color: #ff8f50 !important;
  background: #ff8f50 !important;
  border-radius: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
}

.app-alerts a {
  text-decoration: none !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #0f0742 !important;
  vertical-align: middle;
}

.app-tooltip .tooltip-inner {
  background-color: #fff !important;
  color: #0f0742 !important;
  padding: 8px 12px;
  box-shadow: 0px 2px 13px rgb(16 24 40 / 20%);
}

.app-tooltip .tooltip-arrow::before {
  border-top-color: #fff !important;
  box-shadow: 0px 2px 13px rgb(16 24 40 / 20%);
}

.file-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 126px;
  background: #ffffff;
  border: 1px dashed #e4e7ec;
  border-radius: 8px;
  padding: 16px 24px;
}

.image-name {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #0f0742;
  margin-left: 4px;
}

.input-group .dropdown-toggle {
  background-color: #f2f4f7;
}

.input-group .dropdown-toggle:hover {
  color: black;
}

.image-name-size {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #98a2b3;
  margin-left: 4px;
}

.sm-loader {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #00c2ff;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.page-loading {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: #00000050;
  z-index: 12;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-select * {
  color: #212529 !important;
  font-weight: 400;
}

.image-progress-indicator {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #e4e7ec;
  border-radius: 8px;
  opacity: 0.4;
}

.deal-info-content .image-content img {
  width: 383px;
  height: 100%;
}

.global .form-check-input:checked {
  background-color: #0f0742;
  border-color: #0f0742;
  background-image: url('/public/assets/images/switch-circle.svg') !important;
}
.global .form-check-input {
  width: 44px !important;
  height: 24px !important;
  border-radius: 12px !important;
  margin-top: 0;
}

.disabled {
  cursor: not-allowed !important;
}

.app-select-option__option--is-disabled {
  cursor: not-allowed !important;
}

.border-radius-20 {
  border-radius: 20px;
}

.bg-green-progress .progress-bar {
  background-color: #52ff9a !important;
}
.border-radius-6 {
  border-radius: 6px !important;
}

.centz-input input {
  font-weight: 700 !important;
  border-left: 0;
  padding-left: 0 !important;
}

.merchant-paypal-view {
  padding: 32px 68px;
  background: #52FF9A;
  border-radius: 16px;
  display: flex;
  margin-bottom: 12px;
  margin-top: 12px;
}

.merchant-paypal-view .title {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  color: #0F0742;
}

.merchant-paypal-view .subtitle {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
}

.merchant-paypal-btn {
  background: #0F0742 !important;
  color: #52FF9A !important;
}