.onboarding-step-bar {
  background-color: #0f0742;
  padding: 24px;
}

.onboarding-step-bar .logo {
  width: 50%;
}

.onboarding-step-bar .step-connector {
  width: 2px;
  background: lightgray;
  height: 100%;
  margin: auto;
}

.step-details {
  padding-left: 8px;
  padding-right: 8px;
}

.sidebar-footer {
  background-color: #0f0742;
}

.sidebar-footer p {
  color: #d0d5dd;
  font-size: 14px;
}

.child-menu .list-group-item.active a {
  color: #52ff9a !important;
}

.dropdown-icon {
  margin-top: 10px;
}
.onboarding-step-bar.side-menu .menu-items .menu-links {
  padding-bottom: 404px;
}
.onboarding-step-bar.side-menu .menu-items .sidebar-bottom {
  position: absolute;
  bottom: 0;
  height: auto;
  width: 94%;
}
.mobile-menu-checkbox {
  display: none;
}

@media screen and (max-width: 991px) {
  /* mobile responsive menu */
  .main-dashboard-content-area{
    display: block;
  }
  .onboarding-step-bar.side-menu {
    display: block;
    position: fixed;
    z-index: 5;
    -webkit-user-select: none;
    user-select: none;
    min-height: auto !important;
    overflow-y: initial !important;
    width: 100%;
    top: 0;
  }
  .mobile-menu-checkbox {
    display: inline-block;
  }
  .onboarding-step-bar.side-menu .logo {
    width: 172px !important;
    margin-left: 0 !important;
    z-index: 1;
  }
  .onboarding-step-bar.side-menu input {
    display: block;
    width: 30px;
    height: 21px;
    position: absolute;
    top: 19px;
    right: 15px;
    cursor: pointer;
    opacity: 0; /* hide this */
    z-index: 2; /* and place it over the hamburger */
    -webkit-touch-callout: none;
  }
  .onboarding-step-bar.side-menu .menu-icon {
    position: absolute;
    right: 15px;
    top: 20px;
  }
  .onboarding-step-bar.side-menu .menu-icon .hemburger {
    display: block;
    width: 30px;
    height: 2px;
    margin-bottom: 7px;
    position: relative;
    background: #ffffff;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  }
  .onboarding-step-bar.side-menu .menu-icon .hemburger:first-child {
    transform-origin: 0% 0%;
  }
  .onboarding-step-bar.side-menu .menu-icon .hemburger:nth-last-child(2) {
    transform-origin: 0% 100%;
  }
  .onboarding-step-bar.side-menu input:checked ~ .menu-icon .hemburger {
    opacity: 1;
    transform: rotate(45deg) translate(-7px, -15px);
    background: #ffffff;
  }
  .onboarding-step-bar.side-menu input:checked ~ .menu-icon .hemburger:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }
  .onboarding-step-bar.side-menu input:checked ~ .menu-icon .hemburger:nth-last-child(2) {
    transform: rotate(-45deg) translate(-3px, 13px);
  }
  .onboarding-step-bar.side-menu .menu-items {
    position: fixed;
    width: 35%;
    margin: 15px 0 0 -17px;
    background: #0f0742;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    /* to stop flickering of text in safari */
    transform-origin: 0% 0%;
    transform: translate(-100%, 0);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    height: 100vh;
    overflow-y: auto;
    padding-bottom: 50px;
  }
  .onboarding-step-bar.side-menu input:checked ~ .menu-items {
    transform: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .merchant-admin-content.dashboard-right-side {
    padding-top: 80px !important;
  }
  .onboarding-step-bar.side-menu .menu-items .sidebar-bottom {
    position: initial;
    width: 90%;
    margin: 0 auto;
  }
  .onboarding-step-bar.side-menu .menu-items .menu-links {
    padding-bottom: 0;
  }
  .main-dashboard-content-area .dashboard-left-side {
    min-height: auto;
  }
  .onboarding-step-bar.side-menu .list-group li {
    padding: 10px 16px;
  }
}

@media screen and (max-width:767px){
  .onboarding-step-bar.side-menu .menu-items{
    width: 100%;
    margin: 15px 0 0 -16px
  }
  .onboarding-step-bar.side-menu .menu-items .sidebar-bottom {
    width: 94%;
  }
}

.text-strikethrough {
  text-decoration: line-through !important;
}
