.dashboard-wrapper .deal-info .deal-info-card{
    min-height: 242px;
    border-radius: 18px;
    margin-bottom: 32px;
}
.dashboard-wrapper .deal-info .deal-info-card .card-body{
    padding: 0;
}
.dashboard-wrapper .deal-info .deal-info-card .card-body .deal-info-content{
    display: flex;
}
.dashboard-wrapper .deal-info .deal-info-card .card-body .deal-info-content .image-content{
    width: 383px;
    /* height: 242px; */
}
.dashboard-wrapper .deal-info .deal-info-card .card-body .deal-info-content .image-content img{
    object-fit: cover;
    border-top-left-radius: 18px;
    border-bottom-left-radius: 18px;
}
.dashboard-wrapper .deal-info .deal-info-card .card-body .deal-info-content .text-content{
    width: 100%;
    padding: 24px 64px 24px 35px;
}
.dashboard-wrapper .deal-info .deal-info-card .card-body .deal-info-content .text-content .top-info{
    display: flex;
    justify-content: space-between;
}
.dashboard-wrapper .deal-info .deal-info-card .card-body .deal-info-content .text-content .top-info .logo-name-detail{
    display: flex;
    align-items: center;
    justify-content: center;
}
.dashboard-wrapper .deal-info .deal-info-card .card-body .deal-info-content .text-content .top-info .logo-name-detail .logo{
    margin-right: 16px;
}
.dashboard-wrapper .deal-info .deal-info-card .card-body .deal-info-content .text-content .top-info .logo-name-detail .logo img{
    height: 90px;
    width: 90px;
    border-radius: 100%;
    filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));
}
.dashboard-wrapper .deal-info .deal-info-card .card-body .deal-info-content .text-content .top-info .logo-name-detail .name .title{
    font-size: 24px;
    line-height: 32px;
}
.dashboard-wrapper .deal-info .deal-info-card .card-body .deal-info-content .text-content .top-info .logo-name-detail .name .category{
    font-size: 16px;
    line-height: 24px;
    color: #667085;
    font-weight: 500;
}
.dashboard-wrapper .deal-info .deal-info-card .card-body .deal-info-content .text-content .top-info .edit-profile{
    padding-top: 20px;
}
.dashboard-wrapper .deal-info .deal-info-card .card-body .deal-info-content .text-content .top-info .edit-profile a{
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #98A2B3;
    text-decoration: none;
}
.dashboard-wrapper .deal-info .deal-info-card .card-body .deal-info-content .text-content .top-info .edit-profile a:hover{
    color: #98A2B3;
}
.dashboard-wrapper .deal-info .deal-info-card .card-body .deal-info-content .text-content .top-info .edit-profile a img{
    margin-right: 9px;
    vertical-align: text-top;
}
.dashboard-wrapper .deal-info .deal-info-card .card-body .deal-info-content .text-content .bottom-info{
    margin-top: 16px;
}
.dashboard-wrapper .deal-info .deal-info-card .card-body .deal-info-content .text-content .bottom-info .address-detail{
    display: flex;
    margin-bottom: 8px;
}
.dashboard-wrapper .deal-info .deal-info-card .card-body .deal-info-content .text-content .bottom-info .address-detail img{
    margin-right: 13px;
}
.dashboard-wrapper .deal-info .deal-info-card .card-body .deal-info-content .text-content .bottom-info .address-detail .text-line{
    color: #667085;
    font-weight: 500;
}
.dashboard-wrapper .deal-info .deal-info-card .card-body .deal-info-content .text-content .bottom-info .address-detail .text-line a{
    color: #667085;
    font-weight: 500;
    text-decoration: none;
}
.dashboard-wrapper .main-wrapper-space{
    display: flex;
}
.dashboard-wrapper .main-wrapper-space .left-wrapper{
    width: 58%;
    margin-right: 16px;
}
.dashboard-wrapper .main-wrapper-space .right-wrapper{
    width: 42%;
    margin-left: 16px;
}
.notification-card{
    display: flex;
}
.dashboard-wrapper .main-wrapper-space .box-header{
    display: flex;
    align-items: center;
}
.dashboard-wrapper .main-wrapper-space .box-header img{
    margin-right: 16px;
}
.dashboard-wrapper .main-wrapper-space .box-header .title{
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
    color: #101828;
}
.dashboard-wrapper .main-wrapper-space .card{
    border-radius: 18px;
    border: 1px solid #E4E7EC;
    margin-bottom: 32px;
}
.dashboard-wrapper .main-wrapper-space .card .card-header{
    padding: 20px 16px;
    background-color: transparent;
    box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
    border: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.dashboard-wrapper .main-wrapper-space .card .card-body{
    padding: 24px 16px;
}
.dashboard-wrapper .main-wrapper-space .card .card-body .no-data{
    padding: 48px 0;
}
.dashboard-wrapper .main-wrapper-space .card .card-body .no-data-content{
    margin-top: 18px;
}
.dashboard-wrapper .main-wrapper-space .card .card-body .no-data-content h5{
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 4px;
    color: #101828;
}
.dashboard-wrapper .main-wrapper-space .card .card-body .no-data-content span{
    font-size: 14px;
    line-height: 20px;
    color: #667085;
}
.dashboard-wrapper .main-wrapper-space .card.notification-card .card-body{
    padding: 0;
}
.dashboard-wrapper .main-wrapper-space .card.notification-card table thead{
    background-color: #F9FAFB;
    border: 0;
}
.dashboard-wrapper .main-wrapper-space .card.notification-card table thead th{
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    color: #667085;
    padding: 13px 10px;
}
.dashboard-wrapper .main-wrapper-space .card.notification-card table .action-items{
    display: flex;
}
.dashboard-wrapper .main-wrapper-space .card.notification-card table .redeem a{
    color: #12B76A;
    text-decoration: none;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
}
.dashboard-wrapper .main-wrapper-space .card.notification-card table .redeem a:hover{
    color: #12B76A;
}
.dashboard-wrapper .main-wrapper-space .card.notification-card table .redeem a img{
    margin-right: 3px;
}
.dashboard-wrapper .main-wrapper-space .card.notification-card table .dismiss{
    margin: 0 21px;
}
.dashboard-wrapper .main-wrapper-space .card.notification-card table .dismiss a{
    color: #F04438;
    text-decoration: none;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
}
.dashboard-wrapper .main-wrapper-space .card.notification-card table .dismiss a:hover{
    color: #F04438;
}
.dashboard-wrapper .main-wrapper-space .card.notification-card table .dismiss a img{
    margin-right: 3px;
}
.dashboard-wrapper .main-wrapper-space .card.notification-card table .dot-th{
    width: 5%;
}
.dashboard-wrapper .main-wrapper-space .card.notification-card table .buyer-th{
    /* width: 50%; */
}
.dashboard-wrapper .main-wrapper-space .card.notification-card table{
    font-size: 14px;
    line-height: 20px;
}
.dashboard-wrapper .main-wrapper-space .card.notification-card table .byuer-name,
.dashboard-wrapper .main-wrapper-space .card.notification-card table .value{
    font-weight: 500;
}
.dashboard-wrapper .main-wrapper-space .card.notification-card .table>:not(:first-child) {
    border-top: 0;
}
.dashboard-wrapper .main-wrapper-space .card.notification-card .table>:not(caption)>*>*{
    border-bottom: 0;
}
.dashboard-wrapper .main-wrapper-space .no-data-content .create-new-btn img{
    margin-right: 9px;
    vertical-align: sub;
}
.dashboard-wrapper .main-wrapper-space .no-data-content .create-new-btn:hover img{
    filter: invert(1);
}
.dashboard-wrapper .main-wrapper-space .view-all-link{
    color: #98A2B3;
    font-size: 14px;
    line-height: 20px;
    text-decoration: none;
    font-weight: 500;
}
.dashboard-wrapper .main-wrapper-space .view-all-link:hover{
    color: #98A2B3;
}
.dashboard-wrapper .main-wrapper-space .view-all-link img{
    margin-right: 9px;
    vertical-align: sub;
}
.dashboard-wrapper .main-wrapper-space .active-deal-wrapper .available-deal-content{
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}
.dashboard-wrapper .main-wrapper-space .active-deal-wrapper .available-deal-content .active-deal-box{
    width: 32.66%;
    gap: 8px;
    background-color: #FCFCFD;
    border: 1px solid #E4E7EC;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 14px;
}
.dashboard-wrapper .main-wrapper-space .active-deal-wrapper .available-deal-content .active-deal-box .image-box{
    width: 100%; 
    position: relative;
}
.dashboard-wrapper .main-wrapper-space .active-deal-wrapper .available-deal-content .active-deal-box .image-box img{
    object-fit: cover;
    border-top-right-radius: 14px;
    border-top-left-radius: 14px;
    max-height: 100px;
}
.dashboard-wrapper .main-wrapper-space .active-deal-wrapper .available-deal-content .active-deal-box .image-box .deal-image{
    width: 100%;
}
.dashboard-wrapper .main-wrapper-space .active-deal-wrapper .available-deal-content .active-deal-box .image-box .type-image{
    position: absolute;
    bottom: -21px;
    left: 16px
}
.dashboard-wrapper .main-wrapper-space .active-deal-wrapper .available-deal-content .active-deal-box .content-box{
    padding: 23px 16px 16px 16px;
    text-align: left;
}
.dashboard-wrapper .main-wrapper-space .active-deal-wrapper .available-deal-content .active-deal-box .content-box.orange{
    border-top: 5px solid #FF8F50;
}
.dashboard-wrapper .main-wrapper-space .active-deal-wrapper .available-deal-content .active-deal-box .content-box.green{
    border-top: 5px solid #52FF9A;
}
.dashboard-wrapper .main-wrapper-space .active-deal-wrapper .available-deal-content .active-deal-box .content-box .deal-name{
    line-height: 30px;
}
.dashboard-wrapper .main-wrapper-space .active-deal-wrapper .available-deal-content .active-deal-box .content-box .deal-basic-info{
    color: #667085;
    font-size: 12px;
    line-height: 20px;
}
.dashboard-wrapper .performance-card .performance-content .custom-chart-bg{
    padding: 24px 34px;
    background: #FCFCFD;
    border: 1px solid #E4E7EC;
    border-radius: 14px;
    margin-top: 16px;
}

@media screen and (max-width:1200px){
    .dashboard-wrapper .deal-info .deal-info-card{
        height: auto;
    }
}

@media screen and (max-width:1199px){
    .merchant-admin-container .merchant-admin-content{
        padding: 43px 15px;
    }
    .dashboard-wrapper .deal-info .deal-info-card .card-body .deal-info-content .image-content{
        width: 250px;
        height: auto;
    }
    .dashboard-wrapper .deal-info .deal-info-card .card-body .deal-info-content .image-content img{
        width: 250px;
        height: 100%;
    }
    .dashboard-wrapper .deal-info .deal-info-card .card-body .deal-info-content .text-content{
        padding: 20px;
    }
    .dashboard-wrapper .deal-info .deal-info-card{
        margin-bottom: 16px;
    }
    .dashboard-wrapper .main-wrapper-space .left-wrapper{
        margin-right: 8px;
        width: 50%;
    }
    .dashboard-wrapper .main-wrapper-space .right-wrapper{
        margin-left: 8px;
        width: 50%;
    }
    .dashboard-wrapper .main-wrapper-space .card{
        margin-bottom: 16px;
    }
}

@media screen and (max-width:991px){
    .dashboard-wrapper .deal-info .deal-info-card .card-body .deal-info-content{
        flex-direction: column;
    }
    .dashboard-wrapper .deal-info .deal-info-card .card-body .deal-info-content .image-content{
        width: 100%;
        height: 200px;
    }
    .dashboard-wrapper .deal-info .deal-info-card .card-body .deal-info-content .image-content img{
        width: 100%;
        height: 100%;
        border-bottom-left-radius: 0;
        border-top-right-radius: 18px;
    }
    .dashboard-wrapper .main-wrapper-space{
        flex-wrap: wrap;
    }
    .dashboard-wrapper .main-wrapper-space .left-wrapper,
    .dashboard-wrapper .main-wrapper-space .right-wrapper{
        width: 100%;
        margin-right: 0;
        margin-left: 0;
    }
}

@media screen and (max-width:767px){
    .dashboard-wrapper .deal-info .deal-info-card .card-body .deal-info-content .text-content .top-info{
        flex-direction: column;
    }
    .dashboard-wrapper .deal-info .deal-info-card .card-body .deal-info-content .text-content .top-info .logo-name-detail{
        justify-content: flex-start;
    }
    .dashboard-wrapper .main-wrapper-space .active-deal-wrapper .available-deal-content{
        gap:15px
    }
    .dashboard-wrapper .main-wrapper-space .active-deal-wrapper .available-deal-content .active-deal-box{
        width: 100%;
    }
}

.chart-title {
    font-weight: 400;
    font-family: 'Noto Sans';
    text-align: left;
}